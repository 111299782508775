import { Button, Space, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import FileIcon from '../../../../app/common/components/FileIcon';
import TooltipComponent from '../../../../app/common/components/TooltipComponent';
import TooltipIcon from '../../../../app/common/components/TooltipIcon';
import { ClientOrMatterStatement } from '../../../../app/models/statement';
import useStatementActions from '../../hooks/useStatementActions';

const { Text } = Typography;

type Props = {
    statement: ClientOrMatterStatement;
    showStatementName: boolean;
};

export default observer(function StatementDocumentButton(props: Props) {
    const { statement, showStatementName } = props;

    const { handleViewStatementPDFDocument } = useStatementActions();

    if (!statement.statementDocumentName) {
        return (
            <TooltipComponent title={'The statement document for this statement does not exist.'}>
                <Button type='text' size='small' style={{ display: 'flex', alignItems: 'center' }}>
                    <TooltipIcon />
                </Button>
            </TooltipComponent>
        );
    }

    return (
        <Space>
            {statement.statementDocumentName && (
                <Button
                    type='text'
                    size='small'
                    onClick={() => handleViewStatementPDFDocument(statement)}
                    style={{ display: 'flex', alignItems: 'center' }}
                    title={'Click to preview'}
                >
                    <Space>
                        <FileIcon fileName={statement.statementDocumentName} />
                        {showStatementName && (
                            <Text
                                ellipsis
                                style={{
                                    maxWidth: 150
                                }}
                            >
                                {showStatementName}
                            </Text>
                        )}
                    </Space>
                </Button>
            )}
        </Space>
    );
});
