import { Tabs, TabsProps } from 'antd';
import { observer } from 'mobx-react-lite';
import Activities from '../../../../app/common/components/activities/Activities';
import { ACTIVITY_EVENT_TYPE } from '../../../../app/models/activity';
import { useStore } from '../../../../app/stores/store';
import { StatementActivity } from '../../../../app/models/statement';
import { StatementStatusColor } from '../../../../app/common/constants/color';
import { useParams } from 'react-router-dom';
import { getActivityColor } from '../../../../app/common/utils/activity';

export default observer(function StatementActivities() {
    const { statementId } = useParams<{ statementId: string }>();
    const {
        statementStore: { getStatementActivities, selectedStatement, statementActivities }
    } = useStore();

    const reloadActivities = () => {
        if (selectedStatement) {
            getStatementActivities(selectedStatement.statementId);
        }
    };

    const items: TabsProps['items'] = [
        {
            key: 'all',
            label: 'All',
            children: (
                <Activities<StatementActivity>
                    dataSrc={statementActivities?.map((activity) => ({
                        activity,
                        color: getActivityColor(activity, StatementStatusColor)
                    }))}
                    label={(a) => a.name}
                    description={(a) =>
                        `${a.description ? ` ${a.description}` : `Statement Id - ${statementId}`}`
                    }
                />
            )
        },
        {
            key: 'states',
            label: 'States',
            children: (
                <Activities<StatementActivity>
                    dataSrc={statementActivities
                        ?.filter(
                            (activity) => activity.eventType === ACTIVITY_EVENT_TYPE.STATUS_CHANGED
                        )
                        .map((activity) => ({
                            activity,
                            color: getActivityColor(activity, StatementStatusColor)
                        }))}
                    label={(a) => a.name}
                />
            )
        }
    ];

    return <Tabs items={items} defaultActiveKey='states' onChange={reloadActivities} />;
});
