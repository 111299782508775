import { Checkbox, Typography } from 'antd';
import { truncate } from 'lodash';

type Props = {
    label: string;
    checked: boolean;
    disabled: boolean;
    onClick: () => void;
};

export default function SearchSelectItemRenderer(props: Props) {
    const { label, checked, onClick, disabled } = props;

    return (
        <div
            title={label}
            style={{ width: '100%', cursor: disabled ? 'not-allowed' : 'pointer' }}
            onClick={(e) => {
                e.stopPropagation();
                if (!disabled) {
                    onClick();
                }
            }}
        >
            <Checkbox
                checked={checked}
                disabled={disabled}
                onClick={(e) => {
                    e.stopPropagation();
                    if (!disabled) {
                        onClick();
                    }
                }}
            >
                <Typography.Text>{truncate(label, { length: 30 })}</Typography.Text>
            </Checkbox>
        </div>
    );
}
