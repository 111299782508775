import { PaginatedResult } from '../models/list/pagination';
import { BillerLookup, User, UserPatchProperties } from '../models/user';
import { requests } from './axiosAgent';

const Users = {
    BASE_URL: '/users',
    getUserIdentifierUrl: (userId: string) => `${Users.BASE_URL}/${userId}`,
    get: (userId: string) => requests.get<User>(Users.getUserIdentifierUrl(userId)),
    getAll: (params: URLSearchParams) =>
        requests.get<PaginatedResult<User>>(Users.BASE_URL, { params }),
    billerLookup: (params: URLSearchParams) =>
        requests.get<BillerLookup[]>(`${Users.BASE_URL}/billers`, { params }),
    patchUser: (userId: string, patchProperties: UserPatchProperties) =>
        requests.patch<void>(Users.getUserIdentifierUrl(userId), patchProperties)
};

export default Users;
