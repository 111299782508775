import { Button, Typography } from 'antd';
import pluralize from 'pluralize';

export interface Props {
    entity: string;
    count: number;
    onClear: () => void;
}

export function RowsSelectedLabel({ entity, count, onClear }: Props) {
    return (
        <>
            <Typography.Text type='secondary' style={{ fontSize: 12, fontWeight: 500 }}>
                {count} {pluralize(entity, count)} selected
            </Typography.Text>
            <Button
                type='link'
                onClick={() => onClear()}
                style={{ fontSize: 12, fontWeight: 500, padding: 0 }}
            >
                Clear
            </Button>
        </>
    );
}
