import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import TooltipComponent from '../../../app/common/components/TooltipComponent';
import { ThemeColor } from '../../../app/common/constants/color';
import { isForeignCurrency } from '../../../app/common/utils/tenant';
import { ENTITY_TYPE } from '../../../app/models/entity';
import { Currency } from '../../../app/models/invoice';

interface Props {
    entityType: ENTITY_TYPE;
    currency: Currency;
    large?: boolean;
}

export default observer(function ForeignCurrencyIndicator(props: Props) {
    const { entityType, currency, large } = props;

    if (!isForeignCurrency(currency)) {
        return null;
    }

    return (
        <TooltipComponent title={`This ${entityType} is billed in ${currency.code} currency`}>
            <Typography.Text
                strong
                style={{
                    color: ThemeColor.ColorInfo,
                    verticalAlign: 'middle',
                    fontSize: large ? '1.50rem' : '1rem'
                }}
            >
                {currency.symbol}
            </Typography.Text>
        </TooltipComponent>
    );
});
