// We can maintain the same enum for all type of activities
export enum ACTIVITY_EVENT_TYPE {
    PROJECTED = 'Projected',
    STATUS_CHANGED = 'StatusChanged',
    EXCEPTION = 'Exception',
    EVENT = 'Event',
    MODIFIED = 'Modified',
    WRITE_OFF = 'WriteOff'
}

export interface Activity {
    createdById: string;
    createdByName: string;
    createdOn: Date;
    description: string;
    name: string;
    eventType: ACTIVITY_EVENT_TYPE;
}
