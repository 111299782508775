import { makeAutoObservable } from 'mobx';
import { INVOICE_STATE, INVOICE_TYPE } from '../models/invoice';
import { NAVIGATION_KEY } from '../common/constants/navigationKey';
import { ROLE, LoggedInUser } from '../models/user';
import { UiPreferences } from '../models/uiPreferences';
import { router } from '../../index';
import { store } from './store';
import { StaticRoutes } from '../routing/RoutePaths';
import { STATEMENT_STATE } from '../models/statement';

export default class UiStore {
    selectedNavigationKey: NAVIGATION_KEY = NAVIGATION_KEY.Dashboard;

    uiPreferences: UiPreferences = {
        consolidateInvoiceList: false,
        homePage: '/',
        showMyInvoicesFilter: true,
        showMyClientsFilter: true,
        showInvoiceOverview: false,
        showInvoiceDetailsLedesToggle: false,
        showMyStatementsFilter: true
    };

    constructor() {
        makeAutoObservable(this);
    }

    setNavigationKey = (navigator: NAVIGATION_KEY) => {
        this.selectedNavigationKey = navigator;
    };

    setNavigationKeyByInvoiceState = (invoiceState?: INVOICE_STATE) => {
        switch (invoiceState) {
            case INVOICE_STATE.PENDING:
                this.setNavigationKey(NAVIGATION_KEY.InvoicesPending);
                break;
            case INVOICE_STATE.OUTSTANDING:
                this.setNavigationKey(NAVIGATION_KEY.InvoicesOutstanding);
                break;
            case INVOICE_STATE.PAID:
                this.setNavigationKey(NAVIGATION_KEY.InvoicesPaid);
                break;
            case INVOICE_STATE.CONSOLIDATED:
                this.setNavigationKey(NAVIGATION_KEY.InvoicesConsolidated);
                break;
            default:
                this.setNavigationKey(NAVIGATION_KEY.Invoices);
        }
    };

    setNavigationKeyByStatementState = (statementState?: STATEMENT_STATE) => {
        switch (statementState) {
            case STATEMENT_STATE.SENT:
                this.setNavigationKey(NAVIGATION_KEY.StatementsSent);
                break;
            case STATEMENT_STATE.NOT_SENT:
                this.setNavigationKey(NAVIGATION_KEY.StatementsNotSent);
                break;
            default:
                this.setNavigationKey(NAVIGATION_KEY.Statements);
        }
    };

    setUiPreferences = (user: LoggedInUser | null) => {
        if (!user) {
            return;
        }

        const timekeeper = user.roles.includes(ROLE.TIME_KEEPER);
        const biller = user.roles.includes(ROLE.BILLER);
        const billingManager = user.roles.includes(ROLE.BILLING_MANAGER);
        const admin = user.roles.includes(ROLE.ADMIN);
        const collector = user.roles.includes(ROLE.COLLECTOR);
        const eBillingServices = user.roles.includes(ROLE.EBILLING_SERVICES);

        if (store.userStore.iseBillingServicesUser) {
            store.invoiceStore.invoiceTypeFilter = INVOICE_TYPE.EBILLING;
        }

        // Update myInvoices and myClients filters to false if user not allowed, else
        // use the stored preference.
        const canViewMyInvoicesOrClients = timekeeper || biller || billingManager || collector;
        if (!canViewMyInvoicesOrClients) {
            store.invoiceStore.setMyInvoicesFilter(canViewMyInvoicesOrClients);
            store.clientStore.setMyClientsFilter(canViewMyInvoicesOrClients);
        }

        this.uiPreferences.consolidateInvoiceList = timekeeper;
        this.uiPreferences.homePage = eBillingServices ? StaticRoutes.INVOICE_LIST_PENDING : '/';
        this.uiPreferences.showMyInvoicesFilter = biller || billingManager || collector;
        this.uiPreferences.showMyClientsFilter = biller || billingManager || collector;
        this.uiPreferences.showMyStatementsFilter = biller || billingManager || collector;
        this.uiPreferences.showInvoiceOverview =
            biller || collector || billingManager || admin || eBillingServices || timekeeper;
        this.uiPreferences.showInvoiceDetailsLedesToggle =
            biller || collector || billingManager || admin;
    };

    redirectUserPostLogin = () => {
        const redirectURL = store.userStore.getRedirectUrl();
        router.navigate(redirectURL ?? this.uiPreferences.homePage);
    };
}
