import {
    CheckOutlined,
    DeleteOutlined,
    DownloadOutlined,
    SendOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import { Badge, MenuProps, Space, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { ThemeColor } from '../../../app/common/constants/color';
import { NAVIGATION_KEY } from '../../../app/common/constants/navigationKey';
import { getStatusColor } from '../../../app/common/utils/invoice';
import { addMenuDivider } from '../../../app/common/utils/objectUtils';
import { ENTITY_TYPE } from '../../../app/models/entity';
import { STATEMENT_STATUS } from '../../../app/models/statement';
import { useStore } from '../../../app/stores/store';
import ClientDoNotContactIndicator from '../../clients/indicators/ClientDoNotContactIndicator';
import ClientDoNotSendStatementIndicator from '../../clients/indicators/ClientDoNotSendStatement';
import DetailsCard, { Props as DetailsCardProps } from '../../shared/DetailsCard';
import ForeignCurrencyIndicator from '../../shared/indicators/ForeignCurrencyIndicator';
import useStatementActions from '../hooks/useStatementActions';
import BasicInfoTab from './BasicInfo';

const { Text } = Typography;

export default observer(function StatementDetailsCard() {
    const {
        statementStore: { loadStatement, selectedStatement: statement, downloadStatementDocument },
        clientStore: { client, getClient },
        matterStore: { getMatter },
        uiStore: { setNavigationKey }
    } = useStore();

    const {
        handleSendClientStatement,
        handleShareStatement,
        handleSendMatterStatement,
        handleDeleteStatement,
        handleMarkStatementAsSent
    } = useStatementActions();

    const refreshPage = () => {
        if (statement) {
            loadStatement(statement.statementId, true, ['Activities']);
        }
    };

    useEffect(() => {
        if (statement) {
            setNavigationKey(
                statement.status === STATEMENT_STATUS.ReadyToSend
                    ? NAVIGATION_KEY.StatementsNotSent
                    : NAVIGATION_KEY.StatementsSent
            );
        }
    }, []);

    const detailsCardProps = useMemo(() => {
        const content: DetailsCardProps['content'] = [
            {
                key: 'info',
                label: 'Info',
                children: <BasicInfoTab />
            }
        ];
        const overflowActions: MenuProps['items'] = [];
        const primaryActions: DetailsCardProps['primaryActions'] = [];
        if (!statement?.statementId) {
            return { content, overflowActions, primaryActions };
        }

        // --------------- Construct over flow menu action Items --------------------------
        if (statement.canMarkAsSent) {
            overflowActions.push({
                key: 'markAsSent',
                label: 'Mark Sent',
                icon: <CheckOutlined />,
                onClick: async () => {
                    handleMarkStatementAsSent(statement, refreshPage);
                }
            });
        }

        if (statement.canDownload) {
            if (statement.statementDocumentName) {
                addMenuDivider(overflowActions);
                overflowActions.push({
                    key: 'downloadPdf',
                    label: 'Download',
                    icon: <DownloadOutlined />,
                    onClick: () =>
                        downloadStatementDocument(
                            statement.statementId,
                            statement.statementDocumentName
                        )
                });
            }
        }

        if (statement.canDelete) {
            addMenuDivider(overflowActions);
            overflowActions.push({
                key: 'delete',
                label: 'Delete',
                icon: <DeleteOutlined style={{ color: ThemeColor.ColorError }} />,
                onClick: () => handleDeleteStatement(statement, true)
            });
        }

        // ------- Construct primaryActions -------------------------------
        if (
            statement.canSend !== null &&
            statement.canShare === false &&
            !statement.client.doNotSendStatement
        ) {
            // Primary action for "Send Statement"
            primaryActions.push({
                label: 'Send Statement',
                props: {
                    disabled: !statement.canSend,
                    type: 'primary',
                    icon: <SendOutlined />,
                    onClick: async () => {
                        if (statement.matter?.matterId) {
                            const matter = await getMatter(
                                statement.client.clientId,
                                statement.matter.matterId
                            );
                            if (!matter) {
                                return;
                            }

                            handleSendMatterStatement(
                                matter,
                                statement.statementMonth,
                                true,
                                refreshPage
                            );
                        } else {
                            const client = await getClient(statement.client.clientId);
                            if (!client) {
                                return;
                            }
                            handleSendClientStatement(
                                client,
                                statement.statementMonth,
                                true,
                                refreshPage
                            );
                        }
                    }
                }
            });
        } else if (statement.canShare !== null && statement.canSend === false) {
            // Primary action for "Share" if Send Statement is not available
            primaryActions.push({
                label: 'Share',
                props: {
                    disabled: !statement.canShare,
                    icon: <ShareAltOutlined />,
                    onClick: () => handleShareStatement(statement, refreshPage)
                }
            });
        } else {
            // Overflow action for "Share" if it's not the primary action
            // Add to Top
            overflowActions.unshift({
                key: 'share',
                label: 'Share',
                disabled: !statement.canShare,
                icon: <ShareAltOutlined />,
                onClick: () => handleShareStatement(statement, refreshPage)
            });
        }

        return { content, overflowActions, primaryActions };
    }, [statement, client]);

    const { content, overflowActions, primaryActions } = detailsCardProps;

    return (
        <DetailsCard
            title={
                <Space size={1}>
                    <Text>Statement Details</Text>
                </Space>
            }
            tags={
                <Space size={20}>
                    <ClientDoNotContactIndicator doNotContact={statement?.client.doNotContact} />
                    <ClientDoNotSendStatementIndicator
                        doNotSendStatement={statement?.client.doNotSendStatement}
                    />
                    {statement?.currency && (
                        <ForeignCurrencyIndicator
                            entityType={ENTITY_TYPE.STATEMENT}
                            currency={statement.currency}
                            large={true}
                        />
                    )}
                    <Badge
                        color={getStatusColor(statement?.status)}
                        text={statement?.statusName}
                        style={{ marginRight: 16 }}
                    />
                </Space>
            }
            type='tab'
            overflowActions={overflowActions}
            primaryActions={primaryActions}
            content={content}
        />
    );
});
