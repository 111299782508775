import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import Lookup from './Lookup';
import { SelectProps } from 'antd';
import { useEffect, useState } from 'react';

type MatterLookupProps = {
    clientId: string;
    onSelect: (matterId: string) => void;
    onClear?: () => void;
    disabled?: boolean;
    options?: SelectProps['options'];
};

export default observer(function MatterLookup({
    clientId,
    onSelect,
    onClear,
    disabled,
    options
}: MatterLookupProps) {
    const [matterOptions, setMatterOptions] = useState(options);
    const {
        matterStore: { lookupMatters }
    } = useStore();

    useEffect(() => {
        setMatterOptions(options);
    }, [options]);

    const onQuery = async (queryString: string) => {
        const matters = await lookupMatters(clientId, queryString);
        if (matters) {
            setMatterOptions(
                matters.map(({ matterId, name }) => ({
                    label: `${matterId} - ${name}`,
                    value: matterId
                })) ?? []
            );
            return matters.map(({ matterId, name }) => ({
                label: `${matterId} - ${name}`,
                value: matterId
            }));
        } else {
            setMatterOptions([]);
        }
    };

    return (
        <Lookup
            onSelect={onSelect}
            onClear={onClear}
            onQuery={onQuery}
            placeholder='Search matters'
            disabled={disabled}
            options={matterOptions}
        />
    );
});
