import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { DocumentDetailsActions } from '../../../app/models/invoice';

import { Col, Row, Space } from 'antd';

import { FieldTimeOutlined } from '@ant-design/icons';
import Actions from '../activityBar/Actions';
import { ActionButton } from '../../invoices/details/activityBar/ActionButton';

export default observer(function DocumentDetailsActionsArea() {
    const [action, setAction] = useState<DocumentDetailsActions>(DocumentDetailsActions.ACTIVITY);

    const handleActionChange = (activity: DocumentDetailsActions) => {
        setAction(activity);
    };

    return (
        <Row justify='center' align='middle' gutter={[16, 8]}>
            <Col span={24} className='document-details-action-selectors'>
                <Row justify='start'>
                    <Space align='center' size='large'>
                        <ActionButton
                            pressed={action === DocumentDetailsActions.ACTIVITY}
                            icon={<FieldTimeOutlined />}
                            onClick={() => handleActionChange(DocumentDetailsActions.ACTIVITY)}
                            label='Activity'
                        />
                    </Space>
                </Row>
            </Col>
            <Col span={24}>
                <Actions action={action} />
            </Col>
        </Row>
    );
});
