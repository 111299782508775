import { CloseCircleFilled, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input, SelectProps, Spin } from 'antd';
import { debounce, isEqual, trim } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';

type LookupProps = {
    onSelect: (id: string) => void;
    onClear?: () => void;
    onQuery: (queryString: string) => Promise<SelectProps['options']>;
    placeholder: string;
    disabled?: boolean;
    options?: SelectProps['options'];
};

export default observer(function Lookup({
    onSelect,
    onClear,
    onQuery,
    placeholder,
    disabled = false,
    options = []
}: LookupProps) {
    const [lookupOptions, setLookupOptions] = useState<SelectProps['options']>(options);
    const [prevOptions, setPrevOptions] = useState<SelectProps['options']>(options);
    const [fetching, setFetching] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string | null>(null);

    if (!isEqual(prevOptions, options)) {
        setLookupOptions(options);
        setPrevOptions(lookupOptions);
    }

    const handleSearch = useMemo(() => {
        const loadOptions = async (value: string) => {
            const trimmedValue = trim(value);
            setSearchTerm(trimmedValue);

            if (trimmedValue.length < 3) {
                setLookupOptions(options || []);
                return;
            }

            setFetching(true);
            const results = await onQuery(trimmedValue);
            setLookupOptions(results ?? []);
            setFetching(false);
        };

        return debounce(loadOptions, 500);
    }, [onQuery, options]);

    const noResultsFoundContent = () => {
        if (fetching) {
            return <Spin indicator={<LoadingOutlined />} />;
        }

        if (searchTerm && searchTerm.length < 3) {
            return 'Search term must be at least 3 characters long';
        } else if (searchTerm) {
            return 'No results found';
        }

        return null;
    };

    const handleClear = () => {
        setSearchTerm(null);
        setLookupOptions([]);
        onClear?.();
    };

    return (
        <AutoComplete
            options={lookupOptions}
            onSearch={handleSearch}
            onSelect={onSelect}
            notFoundContent={noResultsFoundContent()}
            disabled={disabled}
            style={{ width: '100%' }}
        >
            <Input
                prefix={<SearchOutlined />}
                allowClear={{
                    clearIcon: <CloseCircleFilled onClick={handleClear} />
                }}
                placeholder={placeholder}
                disabled={disabled}
            />
        </AutoComplete>
    );
});
