import { FileTextTwoTone, StopTwoTone } from '@ant-design/icons';
import { Badge } from 'antd';
import TooltipComponent from '../../../app/common/components/TooltipComponent';
import { ThemeColor } from '../../../app/common/constants/color';

interface Props {
    doNotSendStatement: boolean | undefined | null;
    entityType?: 'Client' | 'Matter';
}

export default function ClientDoNotSendStatementIndicator({
    doNotSendStatement,
    entityType
}: Props) {
    if (!doNotSendStatement) {
        return null;
    }

    return (
        <TooltipComponent
            title={`Please note that the Client should not be sent Statements${entityType === 'Matter' ? ' for this Matter' : ''}.`}
        >
            <Badge
                count={
                    <StopTwoTone
                        twoToneColor={ThemeColor.ColorError}
                        style={{ fontSize: '11px' }}
                    />
                }
                offset={[-1, 1]}
            >
                <FileTextTwoTone twoToneColor={ThemeColor.ColorInfo} />
            </Badge>
        </TooltipComponent>
    );
}
