import { MoreOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Flex, Tour, TourProps, Typography } from 'antd';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { STORAGE_KEY } from '../../models/user';
import TooltipComponent from './TooltipComponent';

export type ListBulkActionProps = {
    key: string;
    icon: ReactNode;
    label: ReactNode;
    onClick: () => void;
    disabled?: boolean;
    tooltip?: React.ReactNode;
};

export type Props = {
    primary?: ListBulkActionProps;
    secondary?: ListBulkActionProps[];
};

export default function ListBulkActions(props: Props) {
    const { primary, secondary } = props;
    const [tourOpen, setTourOpen] = useState(false);
    const dontShowTourAgainRef = useRef(true);
    const primaryActionRef = useRef(null);
    const secondaryActionsRef = useRef(null);

    useEffect(() => {
        const dontShowBulkActionsTour = localStorage.getItem(
            STORAGE_KEY.DONT_SHOW_BULK_ACTIONS_ENHANCEMENT_TOUR
        );
        setTourOpen(!!secondary?.length && dontShowBulkActionsTour !== 'true');
    }, []);

    const handleTourFinish = () => {
        if (dontShowTourAgainRef.current) {
            localStorage.setItem(STORAGE_KEY.DONT_SHOW_BULK_ACTIONS_ENHANCEMENT_TOUR, 'true');
        }
    };

    const steps: TourProps['steps'] = [
        {
            title: 'Primary Action',
            description:
                'The primary action is now easily accessible as a prominent button, positioned outside the overflow menu.',
            placement: 'bottom',
            target: primaryActionRef.current
        },
        {
            title: 'Secondary Actions',
            description: (
                <Flex vertical gap={4}>
                    <Typography.Text>
                        Additional options beyond the primary action now organized in an overflow
                        menu for better usability.
                    </Typography.Text>
                    <Checkbox
                        defaultChecked
                        onChange={(e) => (dontShowTourAgainRef.current = e.target.checked)}
                    >
                        Do not show again
                    </Checkbox>
                </Flex>
            ),
            placement: 'bottom',
            target: secondaryActionsRef.current
        }
    ];

    return (
        <Flex gap={8} align='center'>
            {primary && (
                <TooltipComponent title={primary.tooltip}>
                    <Button
                        type='primary'
                        icon={primary.icon}
                        onClick={primary.onClick}
                        disabled={primary.disabled}
                    >
                        {primary.label}
                    </Button>
                </TooltipComponent>
            )}
            {!!secondary?.length && (
                <Dropdown menu={{ items: secondary }}>
                    <Button
                        type='default'
                        onClick={(e) => e.stopPropagation()}
                        ref={secondaryActionsRef}
                    >
                        <MoreOutlined />
                    </Button>
                </Dropdown>
            )}
            {!!secondary?.length && (
                <Tour
                    open={tourOpen}
                    onClose={() => setTourOpen(false)}
                    steps={steps}
                    onFinish={handleTourFinish}
                />
            )}
        </Flex>
    );
}
