import { useEffect, useRef } from 'react';

export default function useTableScroller() {
    const showScrollBarRef = useRef(true);
    const scrollBarDivRef = useRef<HTMLDivElement>();
    const tableContentRef = useRef<HTMLDivElement>();
    const overflowDivRef = useRef<HTMLDivElement>();
    const tableContentScrolledRef = useRef(false);
    const scrollBarScrolledRef = useRef(false);

    useEffect(() => {
        return () => {
            // Remove all th even listeners to clean up the memory
            window.removeEventListener('scroll', handleWindowScroll);
            window.removeEventListener('resize', handleWindowResize);

            const scrollBarDiv = scrollBarDivRef.current;
            if (scrollBarDiv) {
                scrollBarDiv.removeEventListener('scroll', handleScrollBarDivScroll);
                scrollBarDiv.remove();
            }

            const tableContent = tableContentRef.current;
            if (tableContent) {
                tableContent.removeEventListener('scroll', handleTableContentScroll);
            }
        };
    }, []);

    // We simulate a scrollbar by appending a div to our table overflow content.
    // Once we have the scrollbar inplace we toggle it's visibility based on the scroll offset.
    const addScrollBar = () => {
        const tableContent = document.querySelector('.ant-table-content') as HTMLDivElement;
        if (tableContent) {
            tableContentRef.current = tableContent;

            // Create a div that serves as a placeholder for shown scrollbar
            const scrollBarDiv = document.createElement('div');
            scrollBarDiv.classList.add('custom-scroll-bar');
            scrollBarDivRef.current = scrollBarDiv;

            // Create another div within the above and make it overflow.
            // Thus showing the scrollbar
            const overflowDiv = document.createElement('div');
            overflowDivRef.current = overflowDiv;

            scrollBarDiv.appendChild(overflowDiv);
            tableContent.appendChild(scrollBarDiv);

            scrollBarDiv.addEventListener('scroll', handleScrollBarDivScroll);
            tableContent.addEventListener('scroll', handleTableContentScroll);
            window.addEventListener('scroll', handleWindowScroll);
            window.addEventListener('resize', handleWindowResize);

            // Set the width and visibility on initial mount
            setDivWidths();
            toggleScrollBar();
            reScrollScrollBarDiv();
            reScrollTableContent();
        }
    };

    const handleTableContentScroll = (e: Event) => {
        e.stopPropagation();
        setDivWidths();
        toggleScrollBar();

        // This will prevent 'scroll' event on table content
        if (!tableContentScrolledRef.current) {
            reScrollScrollBarDiv();
        }
        tableContentScrolledRef.current = false;
    };

    const handleScrollBarDivScroll = (e: Event) => {
        e.stopPropagation();

        // This will prevent 'scroll' event on scrollbar hence optimizing performance
        if (!scrollBarScrolledRef.current) {
            reScrollTableContent();
        }
        scrollBarScrolledRef.current = false;
    };

    const handleWindowResize = (e: Event) => {
        e.stopPropagation();
        toggleScrollBar();
        setDivWidths();
    };

    const handleWindowScroll = (e: Event) => {
        e.stopPropagation();
        toggleScrollBar();
    };

    const reScrollTableContent = () => {
        const tableContent = tableContentRef.current;
        const scrollBarDiv = scrollBarDivRef.current;
        if (!(tableContent && scrollBarDiv)) {
            return;
        }

        // Scroll the table content div when user uses the mouse on the 'Appended scroll bar'
        if (scrollBarDiv.scrollLeft !== tableContent.scrollLeft) {
            tableContent.scrollLeft = scrollBarDiv.scrollLeft;
        }
        tableContentScrolledRef.current = true;
    };

    const reScrollScrollBarDiv = () => {
        const tableContent = tableContentRef.current;
        const scrollBarDiv = scrollBarDivRef.current;
        if (!(tableContent && scrollBarDiv)) {
            return;
        }

        // Scroll the 'Appended scroll bar' when actual table content is scrolled
        if (scrollBarDiv.scrollLeft !== tableContent.scrollLeft) {
            scrollBarDiv.scrollLeft = tableContent.scrollLeft;
        }
        scrollBarScrolledRef.current = true;
    };

    const toggleScrollBar = () => {
        const tableContent = tableContentRef.current;
        const scrollBarDiv = scrollBarDivRef.current;
        if (!(tableContent && scrollBarDiv)) {
            return;
        }

        const { top, bottom } = tableContent.getBoundingClientRect();
        const viewHeight = window.innerHeight || document.documentElement.clientHeight;

        // If the table content is out of view port, we toggle the visibility,
        // based on the current visibility state.
        const toggleScrollBar = bottom <= viewHeight || top > viewHeight;
        if (showScrollBarRef.current === toggleScrollBar) {
            showScrollBarRef.current = !toggleScrollBar;
            scrollBarDiv.classList.toggle('custom-scroll-bar-hidden');
        }
    };

    const setDivWidths = () => {
        const tableContent = tableContentRef.current;
        const scrollBarDiv = scrollBarDivRef.current;
        const overflowDiv = overflowDivRef.current;
        if (!(tableContent && scrollBarDiv && overflowDiv)) {
            return;
        }

        scrollBarDiv.style.width = `${tableContent.clientWidth}px`;
        overflowDiv.style.width = `${tableContent.scrollWidth}px`;
    };

    return { addScrollBar };
}
