import React, { useEffect, useState } from 'react';
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Result, Row, Space, Typography } from 'antd';
import Loader from '../../../app/common/components/Loader';
import PdfAnnotator from '../../../app/common/components/pdfAnnotator/PDFAnnotator';
import { printDocumentBlob } from '../../../app/common/utils/file';
import { isUserAgentOnMobile } from '../../../app/common/utils/user';
import mime from 'mime';
import { IHighlight } from 'react-pdf-highlighter';
import { observer } from 'mobx-react-lite';
import './styles.css';

type DocumentViewerProps = {
    document: Blob;
    documentName: string;
    loading: boolean;
    annotations?: IHighlight[];
    allowAnnotations?: boolean;
    onAnnotation?: (updatedAnnotations: string) => Promise<void>;
    extra?: React.ReactNode;
};

export default observer(function DocumentViewer({
    document,
    documentName,
    loading,
    annotations,
    allowAnnotations = false,
    onAnnotation,
    extra
}: DocumentViewerProps) {
    const [docObjectUrl, setDocObjectUrl] = useState<string>('');
    const [error, setError] = useState(false);

    useEffect(() => {
        const type = mime.getType(documentName) ?? 'application/pdf';
        const objectUrl = URL.createObjectURL(new Blob([document], { type }));
        setDocObjectUrl(objectUrl);

        return () => {
            URL.revokeObjectURL(objectUrl);
        };
    }, [document, documentName]);

    if (!docObjectUrl) {
        return null;
    }

    return (
        <Loader spinning={loading}>
            <Row id='pdf-viewer'>
                <Col span={24} id='pdf-toolbar'>
                    <Row justify='space-between' align='middle' style={{ width: '100%' }}>
                        <Col flex='auto'>
                            <Typography.Text strong>{documentName}</Typography.Text>
                        </Col>
                        <Col>
                            <Space>
                                {extra && extra}
                                {!isUserAgentOnMobile() && (
                                    <Button
                                        type='text'
                                        title='Print'
                                        onClick={() => printDocumentBlob(docObjectUrl)}
                                        icon={<PrinterOutlined />}
                                    />
                                )}
                                <Button
                                    type='text'
                                    href={docObjectUrl}
                                    title='Download'
                                    download={documentName}
                                    icon={<DownloadOutlined />}
                                />
                            </Space>
                        </Col>
                    </Row>
                </Col>

                <Col span={24} id='pdf-wrapper-container'>
                    {error ? (
                        <Result
                            status='warning'
                            title='Preview failed'
                            subTitle='Please download the document to view'
                        />
                    ) : (
                        <Col style={{ height: '100%' }}>
                            <PdfAnnotator
                                url={docObjectUrl}
                                annotations={annotations}
                                allowAnnotations={allowAnnotations}
                                onError={() => setError(true)}
                                onAnnotation={onAnnotation}
                            />
                        </Col>
                    )}
                </Col>
            </Row>
        </Loader>
    );
});
