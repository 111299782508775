import { getFormattedDate, getRelativeTime } from '../utils/datetime';
import TooltipComponent from './TooltipComponent';
import { AppConfig } from '../../models/config/appConfig';

export type Props = {
    date?: Date | null | undefined;
    format?: string;
    toolTipPrefix?: string;
    toolTipSuffix?: string;
};

export default function DateWithToolTip({ date, format, toolTipPrefix, toolTipSuffix }: Props) {
    if (!date) {
        return <></>;
    }

    return (
        <TooltipComponent
            title={`${toolTipPrefix ?? ''}${getRelativeTime(date)}${toolTipSuffix ?? ''}`}
        >
            {getFormattedDate(date, format ?? AppConfig.dateMonthNameFormat)}
        </TooltipComponent>
    );
}
