import { Invoice } from '../models/invoice';
import { PaginatedResult } from '../models/list/pagination';
import {
    AssignedMatterTag,
    Matter,
    MatterActivity,
    MatterIdentifier,
    MatterPatchProperties,
    MatterPayor,
    MatterStatement
} from '../models/matter';
import { Note } from '../models/note';
import { StatementActivity } from '../models/statement';
import { requests } from './axiosAgent';
import Clients from './clients';

const Matters = {
    getMatterIdentifierUrl: (clientId: string, matterId: string) =>
        `${Clients.getClientIdentifierUrl(clientId)}/matters/${matterId}`,
    getMatterTagsBaseUrl: (matterId: string) => `/matters/${matterId}/tags`,
    createNote: (
        clientId: string,
        matterId: string,
        content: string,
        sharedToMatterIds: string[],
        tagKeys: string[]
    ) =>
        requests.post<Note>(`${Matters.getMatterIdentifierUrl(clientId, matterId)}/notes`, {
            content,
            sharedToMatterIds,
            tagKeys
        }),
    getActivities: (clientId: string, matterId: string) =>
        requests.get<MatterActivity[]>(
            `${Matters.getMatterIdentifierUrl(clientId, matterId)}/activities`
        ),
    getNotes: (clientId: string, matterId: string, params: URLSearchParams) =>
        requests.get<PaginatedResult<Note>>(
            `${Matters.getMatterIdentifierUrl(clientId, matterId)}/notes`,
            {
                params
            }
        ),
    getMatters: (params: URLSearchParams) =>
        requests.get<PaginatedResult<Matter>>('/v1/matters', { params }),
    getMatter: (clientId: string, matterId: string, params?: URLSearchParams) =>
        requests.get<Matter>(Matters.getMatterIdentifierUrl(clientId, matterId), { params }),
    patchMatter: (clientId: string, matterId: string, patchProperties: MatterPatchProperties) =>
        requests.patch<void>(Matters.getMatterIdentifierUrl(clientId, matterId), patchProperties),
    getStatementActivities: (clientId: string, matterId: string) =>
        requests.get<StatementActivity[]>(
            `${Matters.getMatterIdentifierUrl(clientId, matterId)}/statements/activities`
        ),
    updateBillToEmails: (
        clientId: string,
        matterId: string,
        billToEmails: string[],
        provisional: boolean
    ) =>
        requests.put<void>(`${Matters.getMatterIdentifierUrl(clientId, matterId)}/billtoemails`, {
            billToEmails,
            provisional
        }),
    updateStatementEmails: (
        clientId: string,
        matterId: string,
        statementEmails: string[],
        provisional: boolean
    ) =>
        requests.put<void>(
            `${Matters.getMatterIdentifierUrl(clientId, matterId)}/statementemails`,
            {
                statementEmails,
                provisional
            }
        ),
    getInvoices: (clientId: string, matterId: string, params: URLSearchParams) =>
        requests.get<PaginatedResult<Invoice>>(
            `${Matters.getMatterIdentifierUrl(clientId, matterId)}/invoices`,
            { params }
        ),
    exportInvoicesToExcel: (clientId: string, matterId: string, params: URLSearchParams) =>
        requests.get<Blob>(
            `${Matters.getMatterIdentifierUrl(clientId, matterId)}/invoices/_export`,
            { params, responseType: 'blob' }
        ),
    getPayors: (clientId: string, matterId: string) =>
        requests.get<MatterPayor[]>(`${Matters.getMatterIdentifierUrl(clientId, matterId)}/payors`),
    updateDefaultInvoiceType: (
        clientId: string,
        matterId: string,
        defaultInvoiceType: string | null,
        cascadeUpdate: boolean
    ) =>
        requests.put<void>(
            `${Matters.getMatterIdentifierUrl(clientId, matterId)}/defaultinvoicetype`,
            {
                defaultInvoiceType,
                cascadeUpdate
            }
        ),
    getMatterStatements: (clientId: string, matterId: string, params: URLSearchParams) =>
        requests.get<PaginatedResult<MatterStatement>>(
            `${Matters.getMatterIdentifierUrl(clientId, matterId)}/statements`,
            { params }
        ),
    lookup: (clientId: string, params: URLSearchParams) =>
        requests.get<MatterIdentifier[]>(
            `${Clients.getClientIdentifierUrl(clientId)}/matters/lookup`,
            { params }
        ),
    getAssignedMatterTags: (matterId: string) =>
        requests.get<AssignedMatterTag[]>(Matters.getMatterTagsBaseUrl(matterId)),
    updateAssignedMatterTags: (matterId: string, tagKeys: string[]) =>
        requests.put<void>(Matters.getMatterTagsBaseUrl(matterId), tagKeys)
};

export default Matters;
