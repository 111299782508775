import { ArrowLeftOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
    style?: React.CSSProperties | undefined;
    hideBackButtonWhenNoBackPath?: boolean;
};

export default observer(function BackButton({ style, hideBackButtonWhenNoBackPath }: Props) {
    const navigate = useNavigate();
    const location = useLocation();
    const canGoBack = location.key !== 'default';

    if (!canGoBack && hideBackButtonWhenNoBackPath) {
        return null;
    }

    return (
        <ArrowLeftOutlined
            style={{
                fontSize: '16px',
                ...style
            }}
            onClick={canGoBack ? () => navigate(-1) : undefined}
            className={canGoBack ? 'clickable-icon' : 'disabled-icon'}
        />
    );
});
