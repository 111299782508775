import { ItemType } from 'antd/es/menu/interface';

export function isArrayNullOrEmpty<T>(array: T[] | null | undefined): boolean {
    return !array || !Array.isArray(array) || array.length < 1;
}

export function allPropertiesNullOrEqual(obj: any): boolean {
    if (!obj) {
        return true;
    }

    const values = Object.values(obj);
    if (values.length === 0) {
        return true;
    }

    return values.every((value) => value === values[0] || (value === null && values[0] === null));
}

export function addMenuDivider(menuItems: Array<ItemType>) {
    if (menuItems && menuItems.length > 0 && menuItems[menuItems.length - 1]?.type !== 'divider') {
        menuItems.push({ type: 'divider' });
    }
}

export function cleanMenuDividers(menuItems: Array<ItemType>) {
    if (menuItems && menuItems.length > 0) {
        // remove the first item if its a divider
        if (menuItems[0]?.type === 'divider') {
            menuItems.shift();
        }

        // remove the last item if its a divider
        if (menuItems[menuItems.length - 1]?.type === 'divider') {
            menuItems.pop();
        }
    }
}
