import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { EMAIL_TEMPLATE_TYPE, EmailTemplate } from '../models/template';

export default class TemplateStore {
    loadingEmailTemplates = false;

    // When there are more such templates we can create a reactive collection.
    // But for now this is fine.
    clientReminderEmailTemplates?: EmailTemplate[];
    invoiceSubmissionEmailTemplates?: EmailTemplate[];

    constructor() {
        makeAutoObservable(this);
    }

    getEmailTemplates = async (templateType: EMAIL_TEMPLATE_TYPE) => {
        this.loadingEmailTemplates = true;
        const params = new URLSearchParams();
        params.append('TemplateType', templateType);

        try {
            const emailTemplates = await agent.EmailTemplateValues.getEmailTemplates(params);

            // this is for the custom note option which will be shown by default
            emailTemplates.unshift({
                templateType,
                placeholderName: 'Note',
                name: 'Custom',
                value: null
            });
            runInAction(() => {
                switch (templateType) {
                    case EMAIL_TEMPLATE_TYPE.CLIENT_REMINDER_EMAIL: {
                        this.clientReminderEmailTemplates = emailTemplates;
                        break;
                    }
                    case EMAIL_TEMPLATE_TYPE.INVOICE_SUBMISSION_EMAIL: {
                        this.invoiceSubmissionEmailTemplates = emailTemplates;
                        break;
                    }
                }
            });
            if (templateType === EMAIL_TEMPLATE_TYPE.CLIENT_REMINDER_EMAIL) {
                return this.clientReminderEmailTemplates;
            } else if (templateType === EMAIL_TEMPLATE_TYPE.INVOICE_SUBMISSION_EMAIL) {
                return this.invoiceSubmissionEmailTemplates;
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => (this.loadingEmailTemplates = false));
        }
    };
}
