import { MoreOutlined } from '@ant-design/icons';
import {
    Button,
    ButtonProps,
    Card,
    Dropdown,
    MenuProps,
    Row,
    Space,
    Tabs,
    TabsProps,
    Typography
} from 'antd';
import { ReactNode } from 'react';
const { Text } = Typography;
import './styles.css';

// This is a shared component for creating a details
// card mainly in the profile pages i.e Invoice details, Client details, Matter details
export type Props = {
    title?: ReactNode;
    primaryActions?: { label: string; props: ButtonProps }[];
    overflowActions?: MenuProps['items'];
    type: 'default' | 'tab';
    tags?: ReactNode;
} & (
    | {
          type: 'tab';
          content: TabsProps['items'];
      }
    | {
          type: 'default';
          content: ReactNode;
      }
);
export default function DetailsCard(props: Props) {
    const { title, primaryActions, overflowActions, tags, type = 'default', content } = props;
    return (
        <Card
            title={
                <Row justify='space-between' align='middle' wrap={false}>
                    {typeof title === 'string' ? <Text>{title}</Text> : title}
                    <Space size='small' align='center'>
                        {tags}
                        <Space wrap align='center'>
                            {primaryActions?.map((action, index) => (
                                <Button {...action.props} key={`actionb-${index}`}>
                                    {action.label}
                                </Button>
                            ))}
                        </Space>
                        {overflowActions && overflowActions.length > 0 && (
                            <Dropdown menu={{ items: overflowActions }}>
                                <Button type='default' onClick={(e) => e.stopPropagation()}>
                                    <MoreOutlined />
                                </Button>
                            </Dropdown>
                        )}
                    </Space>
                </Row>
            }
            type='inner'
            // style={cardStyle}
        >
            {type === 'tab' ? (
                <Tabs
                    items={(content as TabsProps['items'])?.map((tab) => ({
                        ...tab,
                        className: 'modified-scroll-bar details-card-content'
                    }))}
                />
            ) : (
                <div className='modified-scroll-bar details-card-content'>
                    {content as ReactNode}
                </div>
            )}
        </Card>
    );
}
