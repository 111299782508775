import { MessageTwoTone, StopTwoTone } from '@ant-design/icons';
import { Badge } from 'antd';
import TooltipComponent from '../../../app/common/components/TooltipComponent';
import { ThemeColor } from '../../../app/common/constants/color';

interface Props {
    doNotContact: boolean | undefined | null;
    entityType?: 'Client' | 'Matter';
}

export default function ClientDoNotContactIndicator({ doNotContact, entityType }: Props) {
    if (!doNotContact) {
        return null;
    }

    return (
        <TooltipComponent
            title={`Please note that the Client should not be contacted for payments or collections${entityType === 'Matter' ? ' for this Matter' : ''}.`}
        >
            <Badge
                count={
                    <StopTwoTone
                        twoToneColor={ThemeColor.ColorError}
                        style={{ fontSize: '11px' }}
                    />
                }
                offset={[-1, 1]}
            >
                <MessageTwoTone twoToneColor={ThemeColor.ColorInfo} />
            </Badge>
        </TooltipComponent>
    );
}
