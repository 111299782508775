import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../app/stores/store';
import DocumentViewer from '../../../shared/documents/DocumentViewer';

export default observer(function StatementDocument() {
    const {
        statementStore: {
            selectedStatement: statement,
            selectedStatementDocument,
            loadingStatement
        }
    } = useStore();

    if (!statement || !selectedStatementDocument) {
        return null;
    }

    return (
        <DocumentViewer
            document={selectedStatementDocument}
            documentName={statement?.statementDocumentName}
            loading={loadingStatement}
        />
    );
});
