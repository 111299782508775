import { BulbOutlined } from '@ant-design/icons';
import { Col, Collapse, CollapseProps, Row, Space, Typography } from 'antd';
import { AppConfig } from '../../../models/config/appConfig';
import { ThemeColor } from '../../constants/color';
import { getFormattedDate, isPastDueDate } from '../../utils/datetime';
import TooltipComponent from '../TooltipComponent';
import UserActivityInfo from '../userActivity/UserActivityInfo';
import RichTextViewer from '../wysiwyg/RichTextViewer';

type ActivityItemProps = {
    label: string;
    description?: string;
    createdBy: string;
    createdOn: Date;
    projected?: boolean;
};

const { Text } = Typography;

export const ActivityItem = (props: ActivityItemProps) => {
    const { label, description, createdBy, createdOn, projected } = props;

    const tooltipInfo = projected
        ? `Predicted on ${getFormattedDate(createdOn, AppConfig.dayDateMonthNameFormat)}`
        : undefined;

    const projectedIndicator = projected ? (
        <TooltipComponent
            title={
                'This activity is calculated using historical patterns and does not represent an actual event.'
            }
        >
            <BulbOutlined
                style={{
                    color: isPastDueDate(createdOn) ? ThemeColor.ColorWarning : ThemeColor.ColorInfo
                }}
            />
        </TooltipComponent>
    ) : null;

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: (
                <Space>
                    {projectedIndicator}
                    <Text
                        style={{ marginLeft: '-10px' }}
                        type={projected ? 'secondary' : undefined}
                    >
                        {label}
                    </Text>
                </Space>
            ),
            children: <RichTextViewer value={description} rows={4} />
        }
    ];

    return (
        <Row>
            <Col span={24}>
                <UserActivityInfo
                    userName={createdBy}
                    activityDateTime={createdOn}
                    tooltipInfo={tooltipInfo}
                    prefix={projected ? 'Predicted • ' : undefined}
                    dateOnly={projected}
                />
            </Col>
            <Col xs={{ span: 24 }}>
                {description ? (
                    <Collapse items={items} expandIconPosition='end' ghost size='small' />
                ) : (
                    <Space>
                        {projectedIndicator}
                        <Text type={projected ? 'secondary' : undefined}>{label}</Text>
                    </Space>
                )}
            </Col>
        </Row>
    );
};
