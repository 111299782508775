import { AuditOutlined, SolutionOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, BreadcrumbProps, Space } from 'antd';
import { Link } from 'react-router-dom';
import BackButton from '../../layout/BackButton';
import { ENTITY_TYPE } from '../../models/entity';
import { Invoice } from '../../models/invoice';
import { Matter } from '../../models/matter';
import { trimWithEllipsis } from '../utils/string';
import TooltipComponent from './TooltipComponent';
import { Statement } from '../../models/statement';

type Props = {
    showBackNavigation?: boolean;
    entityType: ENTITY_TYPE.INVOICE | ENTITY_TYPE.MATTER | ENTITY_TYPE.STATEMENT;
} & (
    | {
          entityType: ENTITY_TYPE.INVOICE;
          invoice: Invoice;
      }
    | {
          entityType: ENTITY_TYPE.MATTER;
          matter: Matter;
      }
    | {
          entityType: ENTITY_TYPE.STATEMENT;
          statement: Statement;
      }
);

export default function NavigationBreadcrumb(props: Props) {
    const { entityType, showBackNavigation } = props;
    const isEntityTypeInvoice = entityType === ENTITY_TYPE.INVOICE;
    const isEntityTypeStatement = entityType === ENTITY_TYPE.STATEMENT;

    const clientId = isEntityTypeInvoice
        ? props.invoice.client.clientId
        : isEntityTypeStatement
          ? props.statement.client.clientId
          : props.matter.client.clientId;

    const clientName = isEntityTypeInvoice
        ? props.invoice.client.name
        : isEntityTypeStatement
          ? props.statement.client.name
          : props.matter.client.name;

    const matterId = isEntityTypeInvoice
        ? props.invoice.matter.matterId
        : isEntityTypeStatement && props.statement.matter
          ? props.statement.matter.matterId
          : null;

    const matterName = isEntityTypeInvoice
        ? props.invoice.matter.name
        : isEntityTypeStatement && props.statement.matter
          ? props.statement.matter.name
          : null;

    const MAX_LEN = 30;

    const clientItem = {
        title: (
            <TooltipComponent title={`Client: ${clientId} - ${clientName}`}>
                <Link to={`/clients/${clientId}`}>
                    <Space>
                        <UserOutlined />
                        {trimWithEllipsis(clientName, MAX_LEN)}
                    </Space>
                </Link>
            </TooltipComponent>
        )
    };

    const matterItem =
        isEntityTypeInvoice || (isEntityTypeStatement && props.statement.matter)
            ? {
                  title: (
                      <TooltipComponent title={`Matter: ${matterId} - ${matterName}`}>
                          <Link to={`/clients/${clientId}/matters/${matterId}`}>
                              <Space>
                                  <AuditOutlined />
                                  {trimWithEllipsis(matterName, MAX_LEN)}
                              </Space>
                          </Link>
                      </TooltipComponent>
                  )
              }
            : null;

    const thirdPartyPayorInvoiceItem =
        isEntityTypeInvoice && props.invoice.payorInvoice
            ? {
                  title: (
                      <TooltipComponent title={`Third-party payor invoice`}>
                          {props.invoice.reversed ? (
                              // For a reversed Payor invoice, most likely the parent is reversed too but we don't have that reversed on date.
                              // Options:
                              // 1. Disable the link
                              // 2. Use browser back button behavior (potentially checking if back url contains the invoice #)
                              // 3. Get reversedon date for parent invoice specified in the DTO.
                              // Decided to go with #1 since that is simpler approach for now, and because this is really an edge case.
                              <Space>
                                  <TeamOutlined />
                                  {props.invoice.parentNumber}
                              </Space>
                          ) : (
                              <Link to={`/invoices/${props.invoice.parentInvoiceId}`}>
                                  <Space>
                                      <TeamOutlined />
                                      {props.invoice.parentNumber}
                                  </Space>
                              </Link>
                          )}
                      </TooltipComponent>
                  )
              }
            : null;

    const payorInvoiceItem =
        isEntityTypeInvoice && props.invoice.payorInvoice
            ? {
                  title: (
                      <TooltipComponent
                          title={`Payor: ${props.invoice.payor!.payorId} - ${props.invoice.payor!.name}`}
                      >
                          <Space>
                              <SolutionOutlined />
                              {trimWithEllipsis(props.invoice.payor!.name, MAX_LEN)}
                          </Space>
                      </TooltipComponent>
                  )
              }
            : null;

    const items: BreadcrumbProps['items'] = [clientItem];
    if (matterItem) {
        items.push(matterItem);
    }
    if (thirdPartyPayorInvoiceItem) {
        items.push(thirdPartyPayorInvoiceItem);
    }
    if (payorInvoiceItem) {
        items.push(payorInvoiceItem);
    }

    return (
        <Space>
            {showBackNavigation && <BackButton style={{ marginLeft: '-5px' }} />}
            <Breadcrumb items={items} />
        </Space>
    );
}
