import { CaretDownFilled } from '@ant-design/icons';
import { Badge, Popover, Space, Tag, Typography } from 'antd';
import { ReactNode } from 'react';
import { ThemeColor } from '../constants/color';
import './styles.css';

type Props = {
    title: string;
    checked: boolean;
    badgeCount?: number;
    onChange?: (checked: boolean) => void;
    popoverTitle?: ReactNode;
    popoverContent?: ReactNode;
};

export default function FilterTag(props: Props) {
    const { title, checked, badgeCount, onChange, popoverTitle, popoverContent } = props;

    const tagClassName = `tag-button ${checked ? 'checked' : ''}`;
    const tagTextClassName = `tag-button__text ${checked ? 'checked' : ''}`;
    return (
        <Popover content={popoverContent} title={popoverTitle}>
            <Tag.CheckableTag className={tagClassName} checked={checked} onChange={onChange}>
                <Space align='center'>
                    <Typography.Text className={tagTextClassName}>{title}</Typography.Text>
                    {!!badgeCount && (
                        <Badge
                            count={badgeCount}
                            color={ThemeColor.ColorInfo}
                            size='small'
                            style={{ fontSize: '10px' }}
                        />
                    )}
                    {badgeCount !== undefined && <CaretDownFilled className={tagTextClassName} />}
                </Space>
            </Tag.CheckableTag>
        </Popover>
    );
}
