import { ReactNode, useState } from 'react';
import FilterTag from './FilterTag';
import './styles.css';

export type Props = {
    title: string;
    onChange: (checked: boolean) => void;
    defaultChecked?: boolean;
    popoverTitle?: ReactNode;
    popoverContent?: ReactNode;
};

export default function BooleanFilter(props: Props) {
    const { title, onChange, defaultChecked, popoverTitle, popoverContent } = props;
    const [checked, setChecked] = useState<boolean>(!!defaultChecked);
    const [prevChecked, setPrevChecked] = useState<boolean>(!!defaultChecked);

    if (prevChecked !== defaultChecked) {
        setPrevChecked(checked);
        setChecked(!!defaultChecked);
    }

    const handleChange = (checked: boolean) => {
        setChecked(checked);
        onChange(checked);
    };

    return (
        <FilterTag
            title={title}
            checked={checked}
            onChange={handleChange}
            popoverContent={popoverContent}
            popoverTitle={popoverTitle}
        />
    );
}
