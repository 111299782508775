import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd/lib';

type Props = {
    warn: boolean;
    title: string;
};

export default function ModalTitle(props: Props) {
    const { warn, title } = props;
    if (!warn) {
        return <>{title}</>;
    }
    return (
        <Space direction='horizontal' size={1}>
            <ExclamationCircleOutlined className='modal-warning-icon' />
            {title}
        </Space>
    );
}
