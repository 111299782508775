import React, { ReactNode } from 'react';
import { Button, Row, Col, Typography, Space } from 'antd';
import './Actions.css';

type ActionButtonProps = {
    icon?: ReactNode;
    label: string;
    onClick?: any;
    pressed: boolean;
};

export const ActionButton = ({ icon, label, onClick, pressed }: ActionButtonProps) => {
    return (
        <Row align='middle' justify='center' style={{ padding: '12px 0' }}>
            <Col>
                <Space direction='vertical' align='center'>
                    <Button
                        icon={icon}
                        onClick={onClick}
                        type='text'
                        className={pressed ? 'action-button-pressed' : ''}
                    />
                    <Typography.Text style={{ fontSize: '10px' }}>{label}</Typography.Text>
                </Space>
            </Col>
        </Row>
    );
};
