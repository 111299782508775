import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Collapse, Space, Typography } from 'antd';
import { useStore } from '../../stores/store';
import TooltipIcon from '../components/TooltipIcon';

const { Paragraph, Text } = Typography;

export default function WebSearchSyntaxHelp() {
    const { modalStore } = useStore();
    interface SearchTip {
        title: string;
        description: string;
    }

    const searchTips: SearchTip[] = [
        {
            title: "'AND' Logic by Default",
            description:
                "All your search terms are automatically combined with an 'AND', and there is no need to use it between your search terms explicitly. For example, searching for 'James payment plan' will find results related to both James and payment plans."
        },
        {
            title: "Search Term Order Doesn't Matter",
            description:
                "Whether you type 'payment plan James' or 'James payment plan', you'll get the same results. The order of terms doesn't affect the search outcome."
        },
        {
            title: 'Respecting Search Term Order with Double Quotes',
            description:
                'To respect search term ordering, you can include the phrase in double quotes. For example, searching for "monthly payment" will specifically target that phrase.'
        },
        {
            title: "Use 'OR' for Broader Search",
            description:
                "Expand your search with 'OR'. For instance, 'James OR Robert payment plan' shows results for payment plans associated with either James, Robert, or both."
        },
        {
            title: "Combine 'OR' with Phrases",
            description:
                'You can also mix \'OR\' with phrases to be more specific. Searching for "annual subscription" OR "monthly plan" can help you find different types of payment plans.'
        },
        {
            title: 'Special Characters Are Ignored',
            description:
                "Commas and similar characters don't impact your search. So, a search for 'James, payment plan' is treated the same as 'James payment plan'."
        },
        {
            title: 'Exclude with a Dash (-)',
            description:
                "If you want to explicitly exclude results with a specific term, prefix it with a dash. For example, searching for 'payment plan -James' will show payment plans not related to James."
        },
        {
            title: 'Happy Searching!',
            description: ''
        }
    ];

    const content = (
        <>
            <Paragraph>
                Our search operates like most search engines, with the following key points:
                <ul>
                    <li>
                        AND logic is used by default, meaning all words must match, regardless of
                        their order. To respect word order, you can enclose the phrase in
                        double-quotes.
                    </li>
                    <li>Include OR between words or phrases to search for either term.</li>
                    <li>A minimum of three characters are required for searching.</li>
                </ul>
            </Paragraph>
            <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                items={[
                    {
                        label: <Text strong>More details</Text>,
                        children: (
                            <>
                                {searchTips.map((tip, index) => (
                                    <Paragraph key={index}>
                                        <Text strong>{tip.title}</Text>
                                        <br />
                                        <Text>{tip.description}</Text>
                                    </Paragraph>
                                ))}
                            </>
                        )
                    }
                ]}
            />
        </>
    );

    const icon = <TooltipIcon />;

    const showModal = () => {
        modalStore.openModal({
            title: (
                <Space>
                    {icon}
                    {'Searching Help'}
                </Space>
            ),
            body: content,
            verticalScroll: true,
            footer: false,
            easyClose: true,
            width: 600
        });
    };

    return <Button type='text' icon={icon} onClick={() => showModal()} />;
}
