import { Space } from 'antd';
import { lowerCase } from 'lodash';
import TooltipIcon from '../../app/common/components/TooltipIcon';
import { ENTITY_TYPE } from '../../app/models/entity';

type Props = {
    entity: ENTITY_TYPE.INVOICE | ENTITY_TYPE.STATEMENT;
};

export default function MultipleClientStatementsSelectedMessage(props: Props) {
    return (
        <Space>
            <TooltipIcon type='warning' />
            {`Please note that the selected ${lowerCase(props.entity)}s belong to multiple clients.`}
        </Space>
    );
}
