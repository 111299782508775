import { Space, Tag } from 'antd';
import TooltipComponent from '../../../app/common/components/TooltipComponent';

type Props = {
    emails: string[] | null;
    maxEmails: number;
};

export default function EmailListViewer({ emails, maxEmails }: Props) {
    if (!emails || emails.length < 1) {
        return <></>;
    }

    // make sure the count is between 1 and array length.
    if (maxEmails > emails.length) {
        maxEmails = emails.length;
    } else if (maxEmails < 1) {
        maxEmails = 1;
    }

    // Show a tag with tooltip if we want to display less emails than there are in the list
    const showTag = maxEmails < emails.length;

    return (
        <TooltipComponent title={showTag ? emails.join(', ') : ''}>
            <Space size={4}>
                {emails.slice(0, maxEmails).join(', ')}
                {showTag && <Tag>+ {emails.length - maxEmails}</Tag>}
            </Space>
        </TooltipComponent>
    );
}
