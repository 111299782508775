import { INVOICE_STATUS } from '../../models/invoice';
import { STATEMENT_STATUS } from '../../models/statement';

export const BarChartColor = {
    PositivePrimary: '#39956C',
    NegetivePrimary: '#AD1A21',
    NeutralPrimary: '#004C99',
    NeutralSecondary: '#BFBFBF'
} as const;

export const PieChartColor = {
    Adjusted: '#FF7875',
    Appealed: '#FFA39E',
    Approved: '#82BC84',
    Delivered: '#78BEBC',
    InClientReview: '#9ACECD',
    InReview: '#B8CBDD',
    InRevision: '#DBE5EE',
    Other: '#9D9393',
    Paid: '#59A55B',
    PaymentSent: '#2F8F32',
    ReadyToReview: '#4D7EAA',
    ReadyToSubmit: '#94B2CC',
    Rejected: '#FF4D4F',
    Reversed: '#FF4D4F',
    Submitted: '#56AEAB',
    Viewed: '#59A55B'
} as const;

export const InvoiceStatusColor: Record<INVOICE_STATUS, string> = {
    Adjusted: '#FF7875',
    Appealed: '#FFA39E',
    Approved: '#82BC84',
    Delivered: '#56AEAB',
    InClientReview: '#9ACECD',
    InReview: '#B8CBDD',
    InRevision: '#DBE5EE',
    Other: '#9D9393',
    Paid: '#59A55B',
    PaymentSent: '#2F8F32',
    ReadyToReview: '#4D7EAA',
    ReadyToSubmit: '#94B2CC',
    Rejected: '#FF4D4F',
    Reversed: '#FF4D4F',
    Submitted: '#7198BB',
    Viewed: '#59A55B'
} as const;

export const InvoiceSubGroupStatusColor: Record<string, string> = {
    // Pending
    InReview: '#AD1A21',
    NotSubmitted: '#AD1A21',

    // Outstanding
    Submitted: '#BFBFBF',
    Appealed: '#FAAD14',
    Adjusted: '#FAAD14',
    Rejected: '#AD1A21',
    Approved: '#47BA87',
    Others: '#BFBFBF',

    // Completed
    Paid: '#47BA87',
    Reversed: '#BFBFBF'
};

export const InvoiceTypeColor = {
    ePDF: '#33A0E3',
    eBilling: '#B7E0E4',
    Physical: '#9AA5AD'
} as const;

export const ThemeColor = {
    ColorPrimary: '#3093D1',
    ColorError: '#DA4747',
    ColorSuccess: '#62BD5B',
    ColorWarning: '#f79547',
    ColorInfo: '#1373CC',
    PrimaryBlack: '#000000',
    PrimaryGrey: '#8C8C8C',
    SecondaryGrey: '#9D9393'
} as const;

export const StatementStatusColor: Record<STATEMENT_STATUS, string> = {
    Delivered: '#56AEAB',
    ReadyToSend: '#9D9393',
    Sent: '#7198BB',
    Viewed: '#59A55B'
} as const;
