import './SearchComponent.css';
import { SearchParams } from '../../models/list/search';
import { Space } from 'antd';
import { Input } from 'antd';
import { useState } from 'react';
import TooltipIcon from '../components/TooltipIcon';
import WebSearchSyntaxHelp from '../search/WebSearchSyntaxHelp';

export interface SearchOptions {
    key: string;
    label: string;
}

export interface Props {
    searchPlaceholderText: string;
    searchParams: SearchParams;
    onSearching: (searchString: string) => void;
    loading?: boolean;
    disabled?: boolean;
    showWebSearchHelp?: boolean;
}

export function SearchComponent(props: Props) {
    const { Search } = Input;
    const [status, setStatus] = useState<'error' | 'searchactive' | undefined>(undefined);
    const [tooltipText, setTooltipText] = useState(props.searchPlaceholderText);

    function onSearching(value: string) {
        value = value.trim();
        if (value.length > 0 && value.length < 3) {
            setTooltipText('Search text should not be less than 3 characters.');
            setStatus('error');
            return;
        }
        setTooltipText(props.searchPlaceholderText);
        props.onSearching(value);
        setStatus(value.length > 0 ? 'searchactive' : undefined);
    }

    return (
        <Space size='small'>
            <Search
                status={status === 'error' ? 'error' : undefined}
                placeholder={props.searchPlaceholderText}
                allowClear
                onSearch={onSearching}
                style={{
                    width: '400px',
                    paddingBottom: '10px',
                    paddingTop: '10px'
                }}
                size='middle'
                defaultValue={props.searchParams.searchString}
                disabled={props.disabled ?? false}
                title={tooltipText}
                prefix={
                    status === 'error' ? (
                        <TooltipIcon type={status} title={tooltipText} />
                    ) : undefined
                }
            />
            {!!props.showWebSearchHelp && <WebSearchSyntaxHelp />}
        </Space>
    );
}
