import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import TableComponent, {
    Props as TableComponentProps
} from '../../../app/common/table/TableComponent';

import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { Flex, Result, Space, TableColumnsType } from 'antd';
import { camelCase, startCase } from 'lodash';
import { NAVIGATION_KEY } from '../../../app/common/constants/navigationKey';
import CheckboxListFilter from '../../../app/common/filter/CheckboxListFilter';
import { SearchComponent } from '../../../app/common/table/SearchComponent';
import PageHeader from '../../../app/layout/PageHeader';
import { PaginationParams } from '../../../app/models/list/pagination';
import { SearchParams } from '../../../app/models/list/search';
import { SortingParams } from '../../../app/models/list/sorting';
import { PAGE_KEY } from '../../../app/models/pageConfigs';
import { ROLE, User, UserFilter } from '../../../app/models/user';
import { useStore } from '../../../app/stores/store';
import useUserActions from './hooks/useUserActions';

export const roleOptions = Object.entries(ROLE)
    .filter(([key]) => key !== 'EBILLING_SERVICES' && key !== 'SECRETARY')
    .map(([key, value]) => ({
        label: startCase(value),
        value: value,
        key: camelCase(key)
    }));

export default observer(function UserList() {
    const {
        userStore: { setUserPreferences },
        userStore: {
            users,
            loadingUsers,
            getAllUsers,
            getSortingParams,
            getSearchParams,
            setSortingParams,
            setPaginationParams,
            setSearchParams,
            userFilters
        },
        uiStore: { setNavigationKey }
    } = useStore();

    const { handleEditUserRoles } = useUserActions();

    useEffect(() => {
        setNavigationKey(NAVIGATION_KEY.SettingsUsers);
        getAllUsers();
    }, []);

    const handleSorting = (sortColumn: string, sortDirection: 'ascend' | 'descend' | undefined) => {
        setSortingParams(PAGE_KEY.USER_LIST, new SortingParams(sortColumn, sortDirection));
        getAllUsers();
    };

    const handlePagination = (activePage: number) => {
        setPaginationParams(PAGE_KEY.USER_LIST, new PaginationParams(activePage));
        getAllUsers();
    };

    const handlePageSizeChange: TableComponentProps<User>['onPageSizeChange'] = (_, pageSize) => {
        setUserPreferences({ pageSize: pageSize });
        handlePagination(1);
    };

    const handleSearching = (searchString: string) => {
        setSearchParams(PAGE_KEY.USER_LIST, new SearchParams(undefined, searchString));
        getAllUsers();
    };

    const handleRoleFilterChange = (selectedRoles: string[]) => {
        userFilters.updateFilter(UserFilter.ROLES, selectedRoles, true);
        getAllUsers();
    };

    const columns: TableColumnsType<User> = [
        {
            title: 'Id',
            dataIndex: 'userId',
            sorter: true
        },
        {
            title: 'Name',
            dataIndex: 'displayName',
            sorter: true
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: true
        },
        {
            title: 'Login Id',
            dataIndex: 'loginId',
            sorter: true
        },
        {
            title: 'Roles',
            dataIndex: 'roles',
            render: (_, record) => (
                <Space>
                    {record.roles.map((role) => startCase(role)).join(', ')}
                    <EditOutlined onClick={() => handleEditUserRoles(record, getAllUsers)} />
                </Space>
            )
        }
    ];

    return (
        <Flex vertical gap={8}>
            <PageHeader title={'Users'} />
            <div style={{ width: 200 }}>
                <CheckboxListFilter
                    options={roleOptions}
                    title={'Roles'}
                    onChange={handleRoleFilterChange}
                    defaultSelections={userFilters.filters[UserFilter.ROLES] ?? []}
                />
            </div>
            <div style={{ width: 300 }}>
                <SearchComponent
                    key={`search-clientList`}
                    searchPlaceholderText={'Search for User Id or Name'}
                    loading={loadingUsers}
                    searchParams={getSearchParams(PAGE_KEY.USER_LIST)}
                    onSearching={handleSearching}
                />
            </div>
            <TableComponent<User>
                columns={columns}
                onSorting={handleSorting}
                onPagination={handlePagination}
                rowKey={'userId'}
                dataSource={users?.items ?? []}
                loading={loadingUsers}
                sortingParams={getSortingParams(PAGE_KEY.USER_LIST)}
                onPageSizeChange={handlePageSizeChange}
                paginationInfo={users?.paginationInfo}
                emptyText={
                    <Result
                        icon={<UserOutlined style={{ color: '#bfbfbf' }} />}
                        subTitle='No users found'
                    />
                }
            />
        </Flex>
    );
});
