import { DownloadOutlined } from '@ant-design/icons';
import { Button, Flex, Space, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { router } from '../../../..';
import { Notify } from '../../../../app/common/utils/notify';
import { ClientOrMatterStatement } from '../../../../app/models/statement';
import { StaticRoutes } from '../../../../app/routing/RoutePaths';
import { useStore } from '../../../../app/stores/store';
import useCheckInitialRoute from '../../../shared/hooks/useCheckFreshRoute';

type Props = {
    statement: ClientOrMatterStatement;
    navigateToParent: boolean;
    onSuccess?: () => Promise<any> | any;
};

export default observer(function DeleteStatementModalBody(props: Props) {
    const { statement, navigateToParent, onSuccess } = props;
    const initialRoute = useCheckInitialRoute();

    const {
        statementStore: { deleteStatement, downloadStatementDocument },
        modalStore: { closeModal }
    } = useStore();

    const handleOnOK = async () => {
        const success = await deleteStatement(statement.statementId);

        if (success) {
            Notify.success('Statement deleted successfully.');
            onSuccess?.();
            if (navigateToParent) {
                if (initialRoute) {
                    router.navigate(StaticRoutes.STATEMENT_LIST_NOT_SENT);
                } else {
                    router.navigate(-1);
                }
            }
        }
        closeModal();
    };

    return (
        <Space direction='vertical' style={{ width: '100%' }}>
            <Typography.Text>Are you sure you want to delete this statement?</Typography.Text>
            <Typography.Text>
                If you need a copy of the statement document, please download it before proceeding.
            </Typography.Text>
            <Button
                icon={<DownloadOutlined />}
                type='link'
                style={{ paddingLeft: '0px' }}
                onClick={() =>
                    downloadStatementDocument(
                        statement.statementId,
                        statement.statementDocumentName
                    )
                }
            >
                {statement.statementDocumentName}
            </Button>
            <Flex justify='flex-end'>
                <Space>
                    <Button onClick={() => closeModal()}>Cancel</Button>
                    <Button onClick={handleOnOK} danger type='primary'>
                        {'Delete'}
                    </Button>
                </Space>
            </Flex>
        </Space>
    );
});
