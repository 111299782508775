import { Button, Checkbox, Col, Flex, Form, Row, Select, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import Loader from '../../../../app/common/components/Loader';
import FormLabel from '../../../../app/common/components/form/FormLabel';
import RichTextEditor from '../../../../app/common/components/wysiwyg/RichTextEditor';
import { Notify } from '../../../../app/common/utils/notify';
import { isForeignCurrency } from '../../../../app/common/utils/tenant';
import { CC_USER } from '../../../../app/models/client';
import { Currency } from '../../../../app/models/invoice';
import { ClientOrMatterStatement } from '../../../../app/models/statement';
import { EMAIL_TEMPLATE_TYPE, EmailTemplate } from '../../../../app/models/template';
import { useStore } from '../../../../app/stores/store';
import CcUsersCheckboxGroup from '../../../invoices/hooks/components/CcUsersCheckboxGroup';
import EditEmailAddresses from '../../../invoices/hooks/components/EditEmailAddresses';
import { getOneTimeExceptionEmailEditorTooltip } from '../../../invoices/hooks/helpers/submit';
import useStatementActions from '../useStatementActions';
import StatementFetchModalBody, { OnChangeData } from './StatementFetchModalBody';

export type Props = {
    clientId: string;
    recipients: string[] | null;
    ccTo?: Exclude<CC_USER, CC_USER.CURRENT_USER>[];
    onSuccess?: () => Promise<any> | any;
    // This is an indication that a matter statement is being sent
    matterId?: string;
    billingTimekeeperName?: string;
    collectionTimekeeperName?: string;
    defaultMonth?: Date;
    needsAttention?: boolean;
    doNotContact?: boolean;
    currency?: Currency;
};

export default observer(function SendStatementModalBody(props: Props) {
    const {
        needsAttention,
        recipients,
        onSuccess,
        matterId,
        ccTo,
        billingTimekeeperName,
        collectionTimekeeperName,
        clientId,
        doNotContact,
        currency,
        defaultMonth
    } = props;
    const {
        statementStore: {
            sendStatement,
            loadingStatementDocument,
            sendingStatement,
            loadingStatementForMonth
        },
        modalStore: { closeModal },
        templateStore: { getEmailTemplates, clientReminderEmailTemplates, loadingEmailTemplates }
    } = useStore();
    const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>(null);
    const [editEmailNote, setEditEmailNote] = useState(false);
    const [existingStatement, setExistingStatement] = useState<ClientOrMatterStatement | null>(
        null
    );
    const [statementMonth, setStatementMonth] = useState<Dayjs | null>(null);
    const [statementDocument, setStatementDocument] = useState<File | null>(null);
    const [amount, setAmount] = useState<number | null>(0);
    const [foreignCurrencyAmount, setForeignCurrencyAmount] = useState<number | null>(0);
    const [ccUsers, setCcUsers] = useState<CC_USER[]>([]);
    const [overrideDnd, setOverrideDnd] = useState(false);
    const [overrideSubmit, setOverrideSubmit] = useState(false);
    const [emailMessage, setEmailMessage] = useState<string | null>(null);
    const [emailRecipients, setEmailRecipients] = useState<string[] | null>(recipients);
    const foreignCurrency = isForeignCurrency(currency);

    const { handleLoadStatementDocument, handleFetchStatement } = useStatementActions();

    useEffect(() => {
        if (clientReminderEmailTemplates === undefined) {
            getEmailTemplates(EMAIL_TEMPLATE_TYPE.CLIENT_REMINDER_EMAIL);
        }
    }, []);

    useEffect(() => {
        if (clientReminderEmailTemplates?.length) {
            setSelectedTemplate(clientReminderEmailTemplates[0]);
        }
    }, [clientReminderEmailTemplates]);

    const handleOnOK = async () => {
        if (
            statementDocument &&
            amount !== null &&
            statementMonth &&
            (foreignCurrency ? foreignCurrencyAmount !== null : true) &&
            emailRecipients
        ) {
            const shouldIncludeAmounts =
                existingStatement?.amount !== amount ||
                existingStatement?.foreignCurrency?.amount !== foreignCurrencyAmount;

            const success = await sendStatement(clientId, {
                statementId: existingStatement?.statementId ?? null,
                statementDocument: statementDocument,
                statementMonth: dayjs(statementMonth).format('YYYY-MM'),
                amount: shouldIncludeAmounts ? amount : null,
                ccUsers,
                emailMessage,
                emailRecipients: !isEqual(emailRecipients, recipients) ? emailRecipients : null,
                matterId: matterId ?? null,
                foreignCurrencyAmount:
                    foreignCurrency && shouldIncludeAmounts ? foreignCurrencyAmount : null
            });

            if (success) {
                Notify.success(`Statement sent successfully`, 'Success');
                onSuccess?.();
            }
        }

        closeModal();
    };

    // We don't allow sending the statement if no amount, month or document
    // is set. And also, if there is dnd enabled it should be overridden
    const disableSendButton = !(
        statementDocument &&
        amount !== null &&
        statementMonth &&
        (!doNotContact || overrideDnd) &&
        (!foreignCurrency || foreignCurrencyAmount !== null) &&
        emailRecipients &&
        emailRecipients.length > 0 &&
        (!existingStatement?.canShare || overrideSubmit)
    );

    const handleMonthChange = useCallback(
        async (dateString: Dayjs | null) => {
            let result: OnChangeData = {
                month: null,
                amount: null,
                document: null,
                foreignCurrencyAmount: null,
                currency: null
            };

            if (!clientId || !dateString) {
                return result;
            }

            const statement = await handleFetchStatement(clientId, dateString, matterId);
            setExistingStatement(statement);

            if (statement) {
                const document = await handleLoadStatementDocument(statement);
                if (document) {
                    setStatementDocument(document);
                    result = {
                        month: dateString,
                        amount: statement.amount,
                        document: document,
                        foreignCurrencyAmount: statement.foreignCurrency?.amount ?? null,
                        currency: statement.currency
                    };
                }
            }
            return result;
        },
        [clientId, matterId]
    );

    const handleOnChange = async (data: OnChangeData) => {
        setStatementDocument(data.document);
        setStatementMonth(data?.month);
        setAmount(data.amount);
        setForeignCurrencyAmount(data.foreignCurrencyAmount);
    };

    return (
        <Loader
            spinning={
                loadingEmailTemplates ||
                loadingStatementDocument ||
                sendingStatement ||
                loadingStatementForMonth
            }
        >
            <Space direction='vertical' style={{ width: '100%' }} size={0}>
                <Row gutter={[12, 0]}>
                    <Col span={24}>
                        <Form.Item>
                            <EditEmailAddresses
                                onChange={(emails) => setEmailRecipients(emails)}
                                emails={recipients ?? ''}
                                tooltipInfo={getOneTimeExceptionEmailEditorTooltip()}
                                defaultEditMode={!!needsAttention || !recipients?.length}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <StatementFetchModalBody
                            clientId={clientId}
                            matterId={matterId}
                            currency={currency}
                            onChange={handleOnChange}
                            onMonthChange={handleMonthChange}
                            disableDate={needsAttention}
                            defaultMonth={defaultMonth}
                        />
                    </Col>
                </Row>
                <>
                    {editEmailNote ? (
                        <Form.Item
                            label={<FormLabel label='Email note' />}
                            colon={false}
                            layout='vertical'
                        >
                            <Space direction='vertical' style={{ width: '100%' }}>
                                {!!clientReminderEmailTemplates?.length && (
                                    <Select
                                        options={clientReminderEmailTemplates.map((template) => ({
                                            value: template.name,
                                            template
                                        }))}
                                        onSelect={(_, option) => {
                                            setSelectedTemplate(option.template);
                                            setEmailMessage(option.template.value);
                                        }}
                                        value={selectedTemplate?.name}
                                    />
                                )}
                                <RichTextEditor
                                    key={`template-key-${selectedTemplate?.name}`}
                                    defaultValue={selectedTemplate?.value ?? ''}
                                    onChange={(comment) => setEmailMessage(comment)}
                                    autoFocus={false}
                                    placeholder='(leave blank to use system default)'
                                />
                            </Space>
                        </Form.Item>
                    ) : (
                        <Form.Item>
                            <Space direction='vertical' size={0}>
                                <FormLabel label='Email note' />
                                <a onClick={() => setEditEmailNote(true)}>Customize</a>
                            </Space>
                        </Form.Item>
                    )}

                    <Form.Item>
                        <CcUsersCheckboxGroup
                            onChange={(ccUsers) => setCcUsers(ccUsers)}
                            billingTimekeeperName={billingTimekeeperName}
                            collectionTimekeeperName={collectionTimekeeperName}
                            ccTo={ccTo ?? []}
                            bulkAction={false}
                        />
                    </Form.Item>

                    {doNotContact && (
                        <Form.Item>
                            <Checkbox onChange={(e) => setOverrideDnd(e.target.checked)}>
                                Override the instructions to not contact the client for payments or
                                collections
                            </Checkbox>
                        </Form.Item>
                    )}

                    {existingStatement?.canShare && (
                        <Form.Item>
                            <Checkbox onChange={(e) => setOverrideSubmit(e.target.checked)}>
                                This Statement was previously sent to the Client. Please confirm
                                that you want to resend it.
                            </Checkbox>
                        </Form.Item>
                    )}
                </>

                <Flex justify='flex-end'>
                    <Space>
                        <Button onClick={() => closeModal()}>Cancel</Button>
                        <Button onClick={handleOnOK} type='primary' disabled={disableSendButton}>
                            {'Send'}
                        </Button>
                    </Space>
                </Flex>
            </Space>
        </Loader>
    );
});
