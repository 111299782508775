import { ReactNode, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Table, TableColumnsType, TableProps } from 'antd';
import { GetRowKey } from 'antd/lib/table/interface';
import { PaginationInfo } from '../../models/list/pagination';
import { SortingParams } from '../../models/list/sorting';
import './TableComponent.css';
import useTableScroller from './useTableScroller';

export interface Props<T> {
    id?: string;
    columns: TableColumnsType<T>;
    rowKey: string | GetRowKey<T>;
    paginationInfo?: PaginationInfo;
    sortingParams?: SortingParams;
    dataSource: T[];
    rowSelection?: TableProps<T>['rowSelection'];
    loading: boolean;
    onSorting?: (sortColumn: string, sortDirection: 'ascend' | 'descend' | undefined) => void;
    onPagination?: (activePage: number) => void;
    rowClick?: boolean;
    onRow?: TableProps<T>['onRow'];
    scroll?: TableProps<T>['scroll'];
    emptyText?: ReactNode;
    onPageSizeChange?: (current: number, pageSize: number) => void;
    showSizeChanger?: boolean;
    pageSizeOptions?: number[];
    rowClassName?: (record: T, index: number) => string;
}

export default function TableComponent<T extends {}>({
    id,
    columns,
    rowKey,
    paginationInfo,
    sortingParams,
    dataSource,
    loading,
    rowSelection,
    onSorting,
    onPagination,
    rowClick,
    onRow,
    scroll,
    emptyText,
    onPageSizeChange,
    showSizeChanger,
    rowClassName,
    pageSizeOptions = [25, 50, 75, 100]
}: Props<T>) {
    const { addScrollBar } = useTableScroller();

    useEffect(() => {
        addScrollBar();
    }, []);

    const onChange: TableProps<any>['onChange'] = (pagination, _, sorter, extra) => {
        // This signifies that the onChange was triggered because of showSizeChanger
        // hence we will not trigger the onChange callback, else handling the pageSize change
        // will become tricky
        if (paginationInfo?.pageSize && paginationInfo.pageSize !== pagination.pageSize) {
            return;
        }
        switch (extra.action) {
            case 'sort': {
                onSorting?.((sorter as any).field, (sorter as any).order);
                break;
            }
            case 'paginate': {
                onPagination?.(pagination.current!);
            }
        }
    };
    const tableLoading = {
        spinning: loading,
        indicator: <LoadingOutlined spin />
    };

    const sortedColumn = sortingParams
        ? columns.find((s) => s.key === sortingParams.sortColumn)
        : null;

    if (sortedColumn) {
        sortedColumn.defaultSortOrder = sortingParams?.sortDirection;
    }

    return (
        <Table
            id={id}
            size='small'
            onChange={onChange}
            pagination={
                paginationInfo
                    ? {
                          pageSize: paginationInfo.pageSize,
                          current: paginationInfo.pageNumber,
                          total: paginationInfo.totalCount,
                          showSizeChanger: showSizeChanger,
                          style: { textAlign: 'right' },
                          disabled: loading,
                          showQuickJumper: false,
                          pageSizeOptions: pageSizeOptions,
                          onShowSizeChange: onPageSizeChange,
                          responsive: true,
                          showLessItems: true
                      }
                    : false
            }
            columns={columns}
            rowKey={rowKey}
            style={rowClick ? { cursor: 'pointer' } : undefined}
            dataSource={dataSource}
            loading={tableLoading}
            showSorterTooltip={false}
            rowSelection={rowSelection ? { ...rowSelection, type: 'checkbox' } : undefined}
            onRow={onRow}
            footer={
                paginationInfo && (paginationInfo.totalCount ?? 0) > 0
                    ? () =>
                          `Showing ${paginationInfo.pageStartAt}-${paginationInfo.pageEndAt} ` +
                          `of ${paginationInfo.totalCount} results`
                    : undefined
            }
            scroll={scroll}
            rowClassName={rowClassName}
            locale={emptyText ? { emptyText: emptyText } : undefined}
            className='table-component'
        />
    );
}
