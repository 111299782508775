import { ACTIVITY_EVENT_TYPE, Activity } from './activity';
import { ClientStatement } from './client';
import { Currency } from './invoice';
import { MatterStatement, MatterType } from './matter';

export interface Statement {
    amount: number;
    activities: StatementActivity[];
    canDownload: boolean | null;
    canDelete: boolean | null;
    canShare: boolean | null;
    canSend: boolean | null;
    canClearError: boolean | null;
    canMarkAsSent: boolean | null;
    createdById: string;
    createdOn: Date;
    error: string | null;
    foreignCurrency: StatementForeignCurrency;
    id: string;
    recipients: string[] | null;
    modifiedById: string;
    modifiedOn: Date;
    client: {
        billingInstructions: string | null;
        clientId: string;
        name: string;
        doNotContact: boolean;
        doNotSendStatement: boolean;
    };
    billingTimekeeperId: string;
    billingTimekeeperName: string;
    currency: Currency;
    statementDocumentAttachmentsCount: number;
    statementDocumentName: string;
    statementId: string;
    statementMonth: Date;
    status: STATEMENT_STATUS;
    statusName: string;
    sentOn: Date | null;
    matter: {
        matterId: string;
        name: string;
        matterType: MatterType;
        leadMatter: boolean;
    } | null;
}

export enum STATEMENT_STATUS {
    ReadyToSend = 'ReadyToSend',
    Sent = 'Sent',
    Delivered = 'Delivered',
    Viewed = 'Viewed'
}

export enum STATEMENT_STATE {
    SENT = 'sent',
    NOT_SENT = 'notSent',
    NEEDS_ATTENTION = 'needsAttention'
}

export type PageKey = 'statementsSent' | 'statementsNotSent' | 'needsAttention';

export type STATEMENT_EVENT =
    | ACTIVITY_EVENT_TYPE.EVENT
    | ACTIVITY_EVENT_TYPE.STATUS_CHANGED
    | ACTIVITY_EVENT_TYPE.EXCEPTION
    | ACTIVITY_EVENT_TYPE.MODIFIED;

export type StatementActivity = Activity & {
    statementId: string;
    statusName: STATEMENT_STATUS;
    eventType: STATEMENT_EVENT;
    status: string;
    exception: boolean;
};

export interface StatementForeignCurrency {
    amount: number;
}

export type StatementIdentifier = {
    statementId: string;
    statementMonth: Date;
};

export type StatementProperty = 'Activities' | 'Payments';

export enum StatementFilter {
    MY_STATEMENTS = 'myStatement',
    CLIENTS = 'clientIds',
    BILLING_TIME_KEEPERS = 'billingTimekeeperIds',
    STATUSES = 'statuses',
    STATEMENT_MONTHS = 'statementMonths',
    NOT_SENT = 'notSent'
}

export interface StatementFilters {
    [StatementFilter.MY_STATEMENTS]: string[];
    [StatementFilter.BILLING_TIME_KEEPERS]: string[];
    [StatementFilter.CLIENTS]: string[];
    [StatementFilter.STATUSES]: string[];
    [StatementFilter.STATEMENT_MONTHS]: string[];
    [StatementFilter.NOT_SENT]: string[];
}

export enum STATEMENT_OWNER_TYPE {
    CLIENT = 'client',
    MATTER = 'matter'
}

export enum LIST_ACTION {
    SEND = 'send',
    MARK_AS_SENT = 'markAsSent',
    SHARE = 'share',
    CLEAR_ERROR = 'clearError'
}

export type ClientOrMatterStatement = ClientStatement | MatterStatement;
