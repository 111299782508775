import { Typography } from 'antd';
import { ReactNode } from 'react';

export type Props = {
    label: string | ReactNode;
};

export default function FormLabel(props: Props) {
    return <Typography.Text strong>{props.label}</Typography.Text>;
}
