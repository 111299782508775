import { Button } from 'antd';
import HTMLReactParser, { DOMNode, domToReact } from 'html-react-parser';
import { memo, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { getTruncationHeight } from './helpers';
import './styles.css';

type Props = {
    value?: string;
    rows?: number;
    className?: string;
};

export default memo(function RichTextViewer(props: Props) {
    const { value, rows, className } = props;
    const [expanded, setExpanded] = useState(false);
    const [truncatable, setTruncatable] = useState(false);
    const viewerRef = useRef<HTMLPreElement | null>(null);

    useLayoutEffect(() => {
        // Access the current viewer using reactRef makes sense,
        // since we cannot use the CSS stylings to target only one
        // this editor using css selectors as it will affect all other viewers.
        const viewer = viewerRef.current;
        if (!rows || !viewer) {
            return;
        }

        // When we use this component within a memoized component,
        // The previous states might be retained, hence resetting it.
        setTruncatable(false);
        setExpanded(false);
        viewer.style.height = 'fit-content';

        const truncationHeight = getTruncationHeight(viewer, rows);
        if (truncationHeight) {
            // Set the height of the editingAreaa
            viewer.style.height = `${truncationHeight}px`;
            setTruncatable(true);
        }
    }, [value]);

    const isEditorExpandable = rows && truncatable && !expanded;

    const handleMoreClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setExpanded(true);
        const viewer = viewerRef.current;
        if (viewer) {
            viewer.style.height = 'fit-content';
        }
    };

    // The html string returned by the quill is well formed,
    // so the comments will be correctly parsed. But some static string (not html)
    // might contain few markers that cannot be parsed. Hence, try to parse the string
    // if possible else show the string as sent by the backend
    const parsedHtml = useMemo(() => {
        if (!value) {
            return null;
        }

        let html: ReturnType<typeof HTMLReactParser> = value;

        // This list should be updated whenever we change the formats in rich text editor.
        // For example, if we include the headings then we have to add h1, h2....
        const whiteListedTags = ['p', 'li', 'ol', 'ul', 'u', 'i', 'em', 'br', 'strong', 'div'];
        const customReplace = (domNode: DOMNode) => {
            if (domNode.type === 'tag') {
                if (!whiteListedTags.includes(domNode.name)) {
                    return (
                        <>
                            &lt;{domNode.name}&gt;{' '}
                            {domToReact(domNode.children as DOMNode[], {
                                replace: customReplace
                            })}
                        </>
                    );
                }
            }
            return domNode;
        };

        try {
            html = HTMLReactParser(value, {
                replace: customReplace
            });
        } catch (error) {
            // Logging to the console for now, like we do in the case of store handlers.
            console.log(error);
        }
        return html;
    }, [value]);

    return (
        <div className={className}>
            <pre className='rich-text__viewer ql-editor' ref={viewerRef}>
                {parsedHtml}
            </pre>
            {isEditorExpandable && (
                <Button type='link' onClick={handleMoreClick} style={{ padding: 0 }}>
                    more...
                </Button>
            )}
        </div>
    );
});
