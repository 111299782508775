import { DatePicker, Flex, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import pluralize from 'pluralize';
import { useState } from 'react';
import ActionForm from '../../../../app/common/components/actionForm/ActionForm';
import { Notify } from '../../../../app/common/utils/notify';
import { CC_USER } from '../../../../app/models/client';
import { AppConfig } from '../../../../app/models/config/appConfig';
import { ENTITY_TYPE } from '../../../../app/models/entity';
import { EMAIL_TEMPLATE_TYPE } from '../../../../app/models/template';
import { NullableType } from '../../../../app/models/utility';
import { useStore } from '../../../../app/stores/store';
import EmailMessageSelector from '../../../shared/EmailMessageSelector';
import MultipleClientStatementsSelectedMessage from '../../../shared/MultipleClientStatementsSelectedMessage';

export type BulkSendOrShareData = NullableType<{
    ccUsers: CC_USER[];
    emailMessage: string;
}>;

type Props = {
    statementIds: string[];
    markAsSent: boolean;
    unSendableStatementsCount: number;
    isMultipleClientSelected: boolean;
    onFinish: () => void;
};

export default function SendStatementsModalBody(props: Props) {
    const {
        modalStore: { closeModal },
        statementStore: { bulkSendStatements }
    } = useStore();
    const {
        statementIds,
        markAsSent,
        onFinish,
        unSendableStatementsCount,
        isMultipleClientSelected
    } = props;
    const [sentOn, setSentOn] = useState<Dayjs | null>(markAsSent ? dayjs() : null);

    const handleOnOk = async (params: BulkSendOrShareData) => {
        const { ccUsers, emailMessage } = params;
        if (statementIds.length) {
            const success = await bulkSendStatements(
                statementIds,
                markAsSent,
                markAsSent && sentOn ? sentOn.format(AppConfig.isoDateFormat) : null,
                ccUsers ?? [],
                emailMessage
            );

            const message = `${statementIds.length} statement(s) ${markAsSent ? 'were successfully marked as sent' : 'sent successfully'}`;
            if (success) {
                Notify.success(message, 'Success');
            }
        }
        onFinish?.();
        closeModal();
    };

    const message = markAsSent
        ? 'Are you sure you want to mark the selected statements as sent?'
        : 'Are you sure you want to send the selected statements?';

    return (
        <ActionForm<BulkSendOrShareData>
            okText={markAsSent ? 'Mark Sent' : 'Send'}
            onOk={handleOnOk}
        >
            <Typography.Text>
                {unSendableStatementsCount
                    ? `${unSendableStatementsCount} ${pluralize('statement', unSendableStatementsCount)} cannot be sent. Click 'Send' to send the remaining statements.`
                    : message}
            </Typography.Text>
            {isMultipleClientSelected && (
                <MultipleClientStatementsSelectedMessage entity={ENTITY_TYPE.STATEMENT} />
            )}
            {!markAsSent && (
                <EmailMessageSelector
                    emailTemplateType={EMAIL_TEMPLATE_TYPE.CLIENT_REMINDER_EMAIL}
                />
            )}
            {!markAsSent && (
                <ActionForm.Item name='ccUsers'>
                    <ActionForm.CcUsers bulkAction={true} ccTo={[CC_USER.BILLING_TIMEKEEPER]} />
                </ActionForm.Item>
            )}
            {markAsSent && (
                <ActionForm.Item>
                    <Flex vertical gap={10}>
                        <Typography.Text>
                            Select the date these statements were sent:
                        </Typography.Text>
                        <DatePicker
                            onChange={(_, dateString) => {
                                if (typeof dateString === 'string') {
                                    setSentOn(dayjs(dateString));
                                }
                            }}
                            defaultValue={sentOn}
                            style={{ width: 160 }}
                            allowClear={false}
                            disabledDate={(current: Dayjs) => {
                                return current && current.isAfter(dayjs(), 'day');
                            }}
                            format={AppConfig.dateMonthNameFormat}
                        />
                    </Flex>
                </ActionForm.Item>
            )}
        </ActionForm>
    );
}
