type TruncationObj = {
    height: number;
    occupiedRows: number;
    shouldTruncate: boolean;
};

export const getTruncationHeightForElementWithNoChildren: (
    ele: Element,
    occupiedRows: number,
    maxRows: number
) => TruncationObj = (ele, occupiedRows, maxRows) => {
    if (occupiedRows >= maxRows) {
        return { height: 0, shouldTruncate: true, occupiedRows };
    }
    const computedStyle = getComputedStyle(ele);

    // The border-boxing might affect the height in different browsers,
    // the difference will be very tiny, but it might affect the row calculation,
    // hence adding the delta 0.2.
    const elementHeight = ele.getBoundingClientRect().height + 0.2;
    let elementLineHeight = parseFloat(computedStyle.lineHeight);

    // Usually the line height will be a number,
    // If it has some inherited string value, then setting it to default 20px;
    if (isNaN(elementLineHeight)) {
        elementLineHeight = 20;
    }

    const rowsRequiredForElement = Math.max(1, Math.floor(elementHeight / elementLineHeight));
    const viewableRowsLeft = maxRows - occupiedRows;

    // If the element can fit into the available rows,
    // then truncation is not required.
    // Else, we have to truncate it.
    if (rowsRequiredForElement <= viewableRowsLeft) {
        return {
            height: elementHeight >= elementLineHeight ? elementHeight : elementLineHeight,
            shouldTruncate: false,
            occupiedRows: occupiedRows + rowsRequiredForElement
        };
    } else {
        return {
            height: viewableRowsLeft * elementLineHeight,
            shouldTruncate: true,
            occupiedRows: occupiedRows + viewableRowsLeft
        };
    }
};

export const getTruncationHeightForElementWithChildren: typeof getTruncationHeightForElementWithNoChildren =
    (ele, occupiedRows, maxRows) => {
        // If the element has already occupied the maxRows, we confirm
        // that truncation is required and stop further computation
        if (occupiedRows >= maxRows) {
            return { height: 0, shouldTruncate: true, occupiedRows };
        }

        // If the element only contains a value with no children,
        // then we return the rows occupied by the value within the element
        if (ele.children.length === 0) {
            return getTruncationHeightForElementWithNoChildren(ele, occupiedRows, maxRows);
        }

        // Recursively calculate whether truncation is required
        let rHeight = 0;
        let rShouldTruncate = false;

        for (const child of ele.children) {
            // Get the height the child occupies along with
            // the number of rows it spans across
            const {
                height,
                shouldTruncate,
                occupiedRows: occupiedRows_
            } = getTruncationHeightForElementWithChildren(child, occupiedRows, maxRows);

            // We update the height and shouldTruncate flag.
            // If we get a true value from any of the recursive calls,
            // we just stop the calculation and return the height to be truncated.
            rHeight += height;
            rShouldTruncate ||= shouldTruncate;
            occupiedRows = occupiedRows_;
            if (rShouldTruncate) {
                return { height: rHeight, shouldTruncate: rShouldTruncate, occupiedRows };
            }
        }

        return { height: rHeight, shouldTruncate: rShouldTruncate, occupiedRows };
    };

export const getTruncationHeight = (ele: Element, maxRows: number) => {
    try {
        const { height, shouldTruncate } = getTruncationHeightForElementWithChildren(
            ele,
            0,
            maxRows
        );
        return shouldTruncate ? height : null;
    } catch (err) {
        console.log(err);
        return null;
    }
};
