import { Button } from 'antd';
import { HTMLAttributeAnchorTarget } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
    children?: React.ReactNode;
    href?: string;
    target?: HTMLAttributeAnchorTarget | undefined;
};

export default function LinkButton({ children, href, target = '_self' }: Props) {
    const navigate = useNavigate();
    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (target === '_self' && href) {
            navigate(href);
        }
    };
    return (
        <Button
            href={target !== '_self' ? href : undefined}
            type='link'
            target={target}
            style={{
                padding: '0px',
                margin: '0px',
                whiteSpace: 'normal',
                textAlign: 'left',
                height: 'auto'
            }}
            // If there is no need to open the link in a new tab
            // we can use react-routers navigate to avoid complete page reload
            onClick={handleClick}
        >
            {children}
        </Button>
    );
}
