import { ReactNode } from 'react';
import TooltipComponent from '../../../app/common/components/TooltipComponent';
import { Typography } from 'antd';

type Props = {
    data: string | ReactNode;
    tooltipInfo?: string | ReactNode;
    minWidth?: number;
};

export default function TruncatedParagraph(props: Props) {
    const { data, minWidth, tooltipInfo } = props;
    return (
        <TooltipComponent title={tooltipInfo ?? data}>
            <Typography.Paragraph
                ellipsis={{ rows: 2 }}
                style={{ marginBottom: '0px', minWidth, overflowX: 'auto' }}
            >
                {data}
            </Typography.Paragraph>
        </TooltipComponent>
    );
}
