import { Descriptions, Space, Typography } from 'antd';
import { Fragment, ReactNode } from 'react';
import TooltipComponent from '../../app/common/components/TooltipComponent';

const { Paragraph } = Typography;

export type Props = {
    descriptions: {
        label?: ReactNode;
        description?: ReactNode;
        tooltipInfo?: ReactNode;
        prefix?: ReactNode;
        suffix?: ReactNode;
        span?: number;
        doNotTruncate?: boolean;
    }[];
    column?: number;
};

export default function DescriptionsComponent(props: Props) {
    const { descriptions, column } = props;
    return (
        <Descriptions
            column={column ?? 2}
            labelStyle={{ color: '#8C8C8C' }}
            layout='vertical'
            contentStyle={{ marginTop: -6 }}
            colon={false}
        >
            {descriptions.map((description_, ind) => {
                const { label, span, prefix, tooltipInfo, description, doNotTruncate, suffix } =
                    description_;
                return (
                    <Fragment key={`description-item-${ind}`}>
                        <Descriptions.Item label={label} span={span}>
                            <Space size='small'>
                                {prefix}
                                {description && (
                                    <TooltipComponent title={tooltipInfo}>
                                        <Paragraph
                                            ellipsis={
                                                !doNotTruncate
                                                    ? {
                                                          rows: 2,
                                                          expandable: true,
                                                          symbol: 'more'
                                                      }
                                                    : false
                                            }
                                            style={{ marginBottom: 4 }}
                                        >
                                            {description}
                                        </Paragraph>
                                    </TooltipComponent>
                                )}

                                {suffix}
                            </Space>
                        </Descriptions.Item>
                    </Fragment>
                );
            })}
        </Descriptions>
    );
}
