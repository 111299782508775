import { DocumentDetailsActions } from '../../../app/models/invoice';
import './Actions';
import StatementActivities from './Activities/StatementActivities';

type Props = {
    action: DocumentDetailsActions;
};
export default function Actions({ action }: Props) {
    switch (action) {
        case 'activity':
            return <StatementActivities />;
        default:
            return null;
    }
}
