import { Space, Typography } from 'antd';
import { NumericFormat } from 'react-number-format';
import { Client, ClientForeignCurrency } from '../../../app/models/client';
import { ENTITY_TYPE } from '../../../app/models/entity';
import { Invoice, InvoiceForeignCurrency } from '../../../app/models/invoice';
import { Matter, MatterForeignCurrency } from '../../../app/models/matter';
import { Tenant } from '../../../app/models/tenant';
import { Statement, StatementForeignCurrency } from '../../../app/models/statement';

const { Text } = Typography;

type Props = {
    foreignCurrencyAmountKey:
        | keyof ClientForeignCurrency
        | keyof InvoiceForeignCurrency
        | keyof StatementForeignCurrency;
    enityType: ENTITY_TYPE;
    tenant?: Tenant;
    entity: Client | Matter | Invoice | Statement;
} & (
    | {
          enityType: ENTITY_TYPE.CLIENT;
          foreignCurrencyAmountKey: keyof ClientForeignCurrency;
          entity: Client;
      }
    | {
          enityType: ENTITY_TYPE.MATTER;
          foreignCurrencyAmountKey: keyof MatterForeignCurrency;
          entity: Matter;
      }
    | {
          enityType: ENTITY_TYPE.INVOICE;
          foreignCurrencyAmountKey: keyof InvoiceForeignCurrency;
          entity: Invoice;
      }
    | {
          enityType: ENTITY_TYPE.STATEMENT;
          foreignCurrencyAmountKey: keyof StatementForeignCurrency;
          entity: Statement;
      }
);

export default function ForeignCurrencyAmount(props: Props) {
    const { tenant, entity, foreignCurrencyAmountKey, enityType } = props;

    if (!(tenant && entity)) {
        return null;
    }

    const getForeignCurrencyAmount = () => {
        if (tenant.currency.code === entity.currency.code) {
            return null;
        }
        switch (enityType) {
            case ENTITY_TYPE.CLIENT:
                return entity.foreignCurrency[foreignCurrencyAmountKey];
            case ENTITY_TYPE.MATTER:
                return entity.foreignCurrency[foreignCurrencyAmountKey];
            case ENTITY_TYPE.INVOICE:
                return entity.foreignCurrency[foreignCurrencyAmountKey];
            case ENTITY_TYPE.STATEMENT:
                return entity.foreignCurrency[foreignCurrencyAmountKey];
        }
    };

    const foreignCurrencyAmount = getForeignCurrencyAmount();
    if (foreignCurrencyAmount === null || undefined) {
        return null;
    }

    return (
        <Space
            size={6}
            style={{
                background: '#f5f5f5',
                borderRadius: '5px',
                padding: 2,
                paddingLeft: 10,
                paddingRight: 10
            }}
        >
            <Text style={{ color: '#8c8c8c', fontSize: 12 }}>{entity.currency.name}</Text>
            <NumericFormat
                style={{ color: '#8c8c8c', fontSize: 12 }}
                displayType='text'
                prefix={entity.currency.symbol}
                value={foreignCurrencyAmount}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator=','
            />
        </Space>
    );
}
