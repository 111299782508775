import { createContext, useContext } from 'react';
import CommonStore from './commonStore';
import InvoiceStore from './invoiceStore';
import ModalStore from './modalStore';
import UiStore from './uiStore';
import UserStore from './userStore';
import ClientStore from './clientStore';
import DashboardStore from './dashboardStore';
import StatementStore from './statementStore';
import TenantStore from './tenantStore';
import ReminderStore from './reminderStore';
import NoteStore from './noteStore';
import MatterStore from './matterStore';
import CacheStore from './cacheStore';
import TemplateStore from './templateStore';
import ErrorStore from './errorStore';
import ConversationStore from './conversationStore';
import TimekeeperStore from './timekeeperStore';
import ProfileInvoicesMenuStore from './profileInvoicesMenuStore';
import DictionaryStore from './dictionaryStore';
import PaymentStore from './paymentStore';
import ProfileStatementsMenuStore from './profileStatementsMenuStore';

interface Store {
    userStore: UserStore;
    modalStore: ModalStore;
    commonStore: CommonStore;
    invoiceStore: InvoiceStore;
    uiStore: UiStore;
    clientStore: ClientStore;
    conversationStore: ConversationStore;
    dashboardStore: DashboardStore;
    statementStore: StatementStore;
    reminderStore: ReminderStore;
    noteStore: NoteStore;
    tenantStore: TenantStore;
    matterStore: MatterStore;
    cacheStore: CacheStore;
    templateStore: TemplateStore;
    errorStore: ErrorStore;
    timekeeperStore: TimekeeperStore;
    profileInvoicesMenuStore: ProfileInvoicesMenuStore;
    dictionaryStore: DictionaryStore;
    paymentStore: PaymentStore;
    profileStatementsMenuStore: ProfileStatementsMenuStore;
}

export const store: Store = {
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    commonStore: new CommonStore(),
    invoiceStore: new InvoiceStore(),
    uiStore: new UiStore(),
    clientStore: new ClientStore(),
    conversationStore: new ConversationStore(),
    dashboardStore: new DashboardStore(),
    statementStore: new StatementStore(),
    reminderStore: new ReminderStore(),
    noteStore: new NoteStore(),
    tenantStore: new TenantStore(),
    matterStore: new MatterStore(),
    cacheStore: new CacheStore(),
    templateStore: new TemplateStore(),
    errorStore: new ErrorStore(),
    timekeeperStore: new TimekeeperStore(),
    profileInvoicesMenuStore: new ProfileInvoicesMenuStore(),
    dictionaryStore: new DictionaryStore(),
    paymentStore: new PaymentStore(),
    profileStatementsMenuStore: new ProfileStatementsMenuStore()
};

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}
