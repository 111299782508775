import { Flex, Space, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import BackButton from './BackButton';

export interface Props {
    title: string | null | undefined;
    subTitle?: string | null | undefined;
    doNotTruncateTitle?: boolean;
    onlyBrowserTitle?: boolean;
    showBackNavigation?: boolean;
    hideBackButtonWhenNoBackPath?: boolean;
}

const { Title } = Typography;
export default observer(function PageHeader({
    title,
    subTitle,
    doNotTruncateTitle,
    onlyBrowserTitle = false,
    showBackNavigation,
    hideBackButtonWhenNoBackPath
}: Props) {
    useEffect(() => {
        if (title && title.length > 0) {
            document.title = 'Oddr - ' + title;
        } else {
            document.title = 'Oddr';
        }
    }, [title]);

    if (onlyBrowserTitle || !title) {
        return null;
    }

    return (
        <Flex vertical>
            <Space>
                {showBackNavigation && (
                    <BackButton
                        style={{ marginBottom: '8px' }}
                        hideBackButtonWhenNoBackPath={hideBackButtonWhenNoBackPath}
                    />
                )}
                <Title
                    ellipsis={doNotTruncateTitle ? undefined : { rows: 2, tooltip: { title } }}
                    level={4}
                    style={{ marginRight: '5px' }}
                >
                    {title}
                </Title>
            </Space>
            {subTitle && <Typography.Text>{subTitle}</Typography.Text>}
        </Flex>
    );
});
