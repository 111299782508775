import { Select, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import ActionForm from '../../app/common/components/actionForm/ActionForm';
import FormLabel from '../../app/common/components/form/FormLabel';
import { EMAIL_TEMPLATE_TYPE, EmailTemplate } from '../../app/models/template';
import { useStore } from '../../app/stores/store';

type Props = {
    emailTemplateType: EMAIL_TEMPLATE_TYPE;
};

export default observer(function EmailMessageSelector(props: Props) {
    const { emailTemplateType } = props;
    const {
        templateStore: {
            invoiceSubmissionEmailTemplates,
            getEmailTemplates,
            clientReminderEmailTemplates
        }
    } = useStore();
    const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>(null);
    const [editEmailNote, setEditEmailNote] = useState(false);

    const templatesToBeUsed =
        emailTemplateType === EMAIL_TEMPLATE_TYPE.CLIENT_REMINDER_EMAIL
            ? clientReminderEmailTemplates
            : invoiceSubmissionEmailTemplates;

    useEffect(() => {
        (async () => {
            if (templatesToBeUsed === undefined) {
                const templates = await getEmailTemplates(emailTemplateType);
                if (templates?.length) {
                    setSelectedTemplate(templates[0]);
                }
            }
        })();
    }, []);

    return (
        <>
            {editEmailNote ? (
                <ActionForm.Item label={<FormLabel label='Email note' />}>
                    <Space direction='vertical' style={{ width: '100%' }}>
                        {!!templatesToBeUsed?.length && (
                            <Select
                                options={templatesToBeUsed?.map((template) => ({
                                    value: template.name,
                                    template
                                }))}
                                onSelect={(_, option) => {
                                    setSelectedTemplate(option.template);
                                }}
                                value={selectedTemplate?.name}
                            />
                        )}
                        <ActionForm.Item name='emailMessage'>
                            <ActionForm.RichTextEditor
                                key={`template-key-${selectedTemplate?.name}`}
                                defaultValue_={selectedTemplate?.value ?? ''}
                                autoFocus={false}
                                placeholder='(leave blank to use system default)'
                            />
                        </ActionForm.Item>
                    </Space>
                </ActionForm.Item>
            ) : (
                <ActionForm.Item>
                    <Space direction='vertical' size={0}>
                        <FormLabel label='Email note' />
                        <a onClick={() => setEditEmailNote(true)}> Customize</a>
                    </Space>
                </ActionForm.Item>
            )}
        </>
    );
});
