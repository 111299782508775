import { Button, Checkbox, Divider, Dropdown, DropdownProps, Flex, MenuProps } from 'antd';
import React, { ReactNode, useState } from 'react';
import '../components/searchSelect/styles.css';
import FilterTag from './FilterTag';
import './styles.css';

type Props = {
    options: { label: ReactNode; key: string }[];
    title: string;
    onChange: (selectedKeys: string[]) => void;
    defaultSelections?: string[];
    showSelectAll?: boolean;
    filterIcon?: (selectionsCount: number) => ReactNode;
    trigger?: DropdownProps['trigger'];
};

export default function CheckboxListFilter(props: Props) {
    const {
        options,
        defaultSelections,
        onChange,
        title,
        showSelectAll = true,
        filterIcon,
        trigger
    } = props;

    const [selections, setSelections] = useState(defaultSelections ?? []);

    const handleItemClick = (key: string, checked: boolean) => {
        const updatedSelections = checked
            ? [...selections, key]
            : selections.filter((selection) => selection !== key);
        setSelections(updatedSelections);
        onChange(updatedSelections);
    };

    const decoratedItems: MenuProps['items'] = options.map(({ label, key }) => ({
        label: (
            <div onClick={(e) => e.stopPropagation()}>
                <Checkbox
                    key={key}
                    checked={selections.includes(key)}
                    onChange={(e) => handleItemClick(key, e.target.checked)}
                    onClick={(e) => e.stopPropagation()}
                >
                    {label}
                </Checkbox>
            </div>
        ),
        key,
        style: { backgroundColor: selections.includes(key) ? '' : 'white' },
        onClick: ({ domEvent }) => domEvent.stopPropagation()
    }));

    const menuProps: MenuProps = {
        items: decoratedItems,
        selectable: true,
        multiple: true,
        defaultSelectedKeys: selections.length ? defaultSelections : undefined,
        onSelect: ({ key }) => handleItemClick(key, true),
        onDeselect: ({ key }) => handleItemClick(key, false),
        onClick: ({ domEvent }) => domEvent.stopPropagation()
    };

    const handleSelectOrClearAll = (clear: boolean) => {
        if (clear && selections.length) {
            setSelections([]);
            onChange([]);
        } else if (!clear) {
            const selectedKeys = options.map((option) => option.key);
            setSelections(selectedKeys);
            onChange(selectedKeys);
        }
    };

    return (
        <Dropdown
            menu={menuProps}
            trigger={trigger ?? ['click']}
            dropdownRender={(menus) => (
                <Flex vertical wrap='wrap' gap='small' className='filter__lookup--wrapper'>
                    {showSelectAll && (
                        <>
                            <Flex justify='space-between' align='center'>
                                <Checkbox
                                    onChange={(e) => handleSelectOrClearAll(!e.target.checked)}
                                    checked={selections.length === options.length}
                                    style={{ paddingLeft: 15 }}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    Select All
                                </Checkbox>
                                <Button
                                    type='link'
                                    onClick={() => handleSelectOrClearAll(true)}
                                    style={{
                                        alignSelf: 'end',
                                        paddingRight: 2
                                    }}
                                >
                                    Clear
                                </Button>
                            </Flex>
                            <Divider style={{ margin: 0 }} />
                        </>
                    )}

                    {React.cloneElement(menus as React.ReactElement, {
                        style: { boxShadow: 'none', maxHeight: 400, overflow: 'auto' }
                    })}
                </Flex>
            )}
        >
            <div>
                {filterIcon ? (
                    filterIcon(selections.length)
                ) : (
                    <FilterTag
                        title={title}
                        checked={!!selections.length}
                        badgeCount={selections.length}
                    />
                )}
            </div>
        </Dropdown>
    );
}
