import { useState } from 'react';
import { useStore } from '../../../../app/stores/store';
import { ROLE, User } from '../../../../app/models/user';
import { Notify } from '../../../../app/common/utils/notify';
import { isEqual } from 'lodash';
import { Form, Select } from 'antd';
import ActionFormFooter from '../../../../app/common/components/actionForm/ActionFormFooter';
import { roleOptions } from '../UserList';

export default function useUserActions() {
    const {
        modalStore: { openModal, closeModal },
        userStore: { patchUser }
    } = useStore();

    const handleEditUserRoles = (user: User, onSuccess: () => Promise<any> | any) => {
        const ModalBody = () => {
            const [roles, setRoles] = useState<ROLE[]>(user.roles);

            const handleOnOK = async () => {
                const success = await patchUser(user.userId, { roles: roles });
                if (success) {
                    Notify.success(
                        `Roles Changed successfully for ${user.displayName} `,
                        'Success'
                    );
                }
                closeModal();
                onSuccess();
            };

            return (
                <Form>
                    <Form.Item>
                        <Select
                            options={roleOptions}
                            onChange={(roles) => {
                                setRoles(roles);
                            }}
                            mode='tags'
                            defaultValue={user.roles}
                        />
                    </Form.Item>

                    <ActionFormFooter
                        onOk={handleOnOK}
                        okDisabled={isEqual(user.roles, roles)}
                        okText='Save'
                    />
                </Form>
            );
        };

        openModal({
            title: `Edit Roles for ${user.displayName}`,
            body: <ModalBody />,
            footer: false
        });
    };

    return {
        handleEditUserRoles
    };
}
