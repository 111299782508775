import { Tabs, TabsProps } from 'antd';
import { useCallback } from 'react';
import StatementDocument from './statement/StatementDocument';

export const enum MENU {
    STATEMENT = 'statement'
}

export default function Menus() {
    const getTabItems = useCallback(() => {
        const tabItems: TabsProps['items'] = [
            {
                key: MENU.STATEMENT,
                label: 'Statement',
                children: <StatementDocument />
            }
        ];
        return tabItems;
    }, []);

    return <Tabs items={getTabItems()} activeKey={MENU.STATEMENT} />;
}
