import { Result, Timeline } from 'antd';
import { ActivityItem } from './ActivityItem';
import Loader from '../Loader';
import { FieldTimeOutlined } from '@ant-design/icons';
import { ReactNode, useMemo } from 'react';
import { ACTIVITY_EVENT_TYPE, Activity } from '../../../models/activity';

export type Props<T> = {
    dataSrc: { activity: T; color?: string; dot?: ReactNode }[];
    loading?: boolean;
    label: (activity: T) => string;
    description?: (activity: T) => string | undefined;
};

export default function Activities<T extends Activity>(props: Props<T>) {
    const { dataSrc, loading = false, label, description } = props;

    const items = useMemo(() => {
        const projectedActivities: typeof dataSrc = [];
        const actualActivities: typeof dataSrc = [];

        // The timeline of the activities will not be affected since we are
        // traversing linearly
        dataSrc.forEach((data) => {
            if (data.activity.eventType === ACTIVITY_EVENT_TYPE.PROJECTED) {
                projectedActivities.push(data);
            } else {
                actualActivities.push(data);
            }
        });
        return [...projectedActivities, ...actualActivities];
    }, [dataSrc, loading]);

    return (
        <Loader spinning={loading} label=''>
            <div
                className='modified-scroll-bar-hover'
                style={{ maxHeight: '1150px', overflow: 'auto' }}
            >
                {items.length ? (
                    <Timeline
                        mode='left'
                        style={{ width: '100%' }}
                        items={items.map(({ activity, color }, index) => ({
                            key: `activity-${index}`,
                            color: color,
                            children: (
                                <ActivityItem
                                    createdBy={activity.createdByName}
                                    createdOn={activity.createdOn}
                                    label={label(activity)}
                                    description={description?.(activity)}
                                    projected={activity.eventType === ACTIVITY_EVENT_TYPE.PROJECTED}
                                />
                            )
                        }))}
                    />
                ) : (
                    <Result
                        icon={<FieldTimeOutlined style={{ color: '#bfbfbf' }} />}
                        subTitle='No activities'
                    />
                )}
            </div>
        </Loader>
    );
}
