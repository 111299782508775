import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { isArray, join } from 'lodash';
import { useState } from 'react';
import TooltipIcon from '../../../../app/common/components/TooltipIcon';
import FormLabel from '../../../../app/common/components/form/FormLabel';
import EmailListInput from '../../../shared/emailList/EmailListInput';
import { splitEmails } from '../../../shared/emailList/helper';

type Props = {
    onChange: (emails: string[], isValid: boolean) => void;
    emails: string | string[];
    tooltipInfo: string;
    defaultEditMode: boolean;
};

export default function EditEmailAddresses(props: Props) {
    const { onChange, emails, tooltipInfo, defaultEditMode } = props;
    const [editMode, setEditMode] = useState(defaultEditMode);

    const handleButtonClick = () => {
        if (editMode) {
            onChange(isArray(emails) ? emails : splitEmails(emails), true);
        }
        setEditMode(!editMode);
    };
    return (
        <Space direction='vertical' size={0} style={{ width: '100%' }}>
            <Space>
                <FormLabel label='Recipients' />
                <TooltipIcon type='insight' title={tooltipInfo} />
                <Button
                    icon={editMode ? <CloseCircleOutlined /> : <EditOutlined />}
                    type={'text'}
                    onClick={handleButtonClick}
                />
            </Space>

            {editMode ? (
                <EmailListInput onChange={onChange} emails={emails} />
            ) : (
                join(isArray(emails) ? emails : [emails], ', ')
            )}
        </Space>
    );
}
