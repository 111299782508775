import { Typography } from 'antd';
import pluralize from 'pluralize';
import ActionForm from '../../../../app/common/components/actionForm/ActionForm';
import { Notify } from '../../../../app/common/utils/notify';
import { CC_USER } from '../../../../app/models/client';
import { ENTITY_TYPE } from '../../../../app/models/entity';
import { EMAIL_TEMPLATE_TYPE } from '../../../../app/models/template';
import { useStore } from '../../../../app/stores/store';
import EmailMessageSelector from '../../../shared/EmailMessageSelector';
import MultipleClientStatementsSelectedMessage from '../../../shared/MultipleClientStatementsSelectedMessage';
import { BulkSendOrShareData } from './SendStatementsModalBody';

type Props = {
    statementIds: string[];
    unShareableStatementsCount: number;
    isMultipleClientsSelected: boolean;
    onFinish: () => void;
};

export default function ShareStatementsModalBody(props: Props) {
    const { statementIds, onFinish, unShareableStatementsCount, isMultipleClientsSelected } = props;
    const {
        statementStore: { shareStatements },
        modalStore: { closeModal }
    } = useStore();

    const handleOnOk = async (params: BulkSendOrShareData) => {
        const { ccUsers, emailMessage } = params;
        if (statementIds.length) {
            const success = await shareStatements(statementIds, ccUsers ?? [], emailMessage);
            if (success) {
                Notify.success(
                    `${statementIds.length} statements(s) were successfully shared`,
                    'Success'
                );
            }
        }
        onFinish();
        closeModal();
    };

    return (
        <ActionForm<BulkSendOrShareData> okText='Share' onOk={handleOnOk}>
            <Typography.Text>
                {unShareableStatementsCount
                    ? `${unShareableStatementsCount} ${pluralize('statement', unShareableStatementsCount)} cannot be shared. Click 'Share' to share the remaining statements.`
                    : 'Are you sure you want to share the selected statements with the clients?'}
            </Typography.Text>
            {isMultipleClientsSelected && (
                <MultipleClientStatementsSelectedMessage entity={ENTITY_TYPE.STATEMENT} />
            )}
            <EmailMessageSelector emailTemplateType={EMAIL_TEMPLATE_TYPE.CLIENT_REMINDER_EMAIL} />
            <ActionForm.Item name='ccUsers'>
                <ActionForm.CcUsers bulkAction={true} ccTo={[CC_USER.BILLING_TIMEKEEPER]} />
            </ActionForm.Item>
        </ActionForm>
    );
}
