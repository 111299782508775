import { Col, Row, Skeleton } from 'antd';

// This can be used for details page like invoice details,
// client profile, matter profile etc. i.e pages where there
// is Card information.
export default function DetailsPageLoading() {
    return (
        <Row>
            <Col flex='auto'>
                <Skeleton.Input active />
                <br />
                &nbsp;
                <br />
                <Skeleton active title={false} paragraph={{ rows: 6 }} />
                <Skeleton
                    avatar={{ size: 850, shape: 'square' }}
                    title={false}
                    active
                    paragraph={false}
                />
            </Col>
            <Col offset={1} flex='250px'>
                <Skeleton active />
            </Col>
        </Row>
    );
}
