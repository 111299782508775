import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import Lookup from './Lookup';

type ClientLookupProps = {
    onSelect: (clientId: string) => void;
    onClear?: () => void;
    disabled?: boolean;
};

export default observer(function ClientLookup({ onSelect, onClear, disabled }: ClientLookupProps) {
    const {
        clientStore: { queryClients }
    } = useStore();

    const onQuery = async (queryString: string) => {
        const results = await queryClients(queryString);
        return (
            results?.items.map((client) => ({
                label: `${client.clientId} - ${client.name}`,
                value: client.clientId
            })) ?? []
        );
    };

    return (
        <Lookup
            onSelect={onSelect}
            onClear={onClear}
            onQuery={onQuery}
            placeholder='Search clients'
            disabled={disabled}
        />
    );
});
