import { ACTIVITY_EVENT_TYPE, Activity } from './activity';
import { ClientIdentifier } from './client';
import { Currency, INVOICE_TYPE } from './invoice';
import { KeyName } from './keyValuePair';
import { Payor } from './payor';
import { Person } from './person';
import { Statement } from './statement';

export interface MatterPayorPercentages {
    billedOnAccount: number | null;
    fees: number | null;
    hardCosts: number | null;
    other: number | null;
    softCosts: number | null;
    tax: number | null;
    interest: number | null;
}

export interface MatterPayor {
    payor: Payor;
    default: boolean;
    percentages: MatterPayorPercentages;
    payorPerson: Person | null;
}

export interface MatterMetrics {
    avgDaysToView: number | null;
    collectionRealization: number;
    avgDso: number | null;
    ar0To30: number;
    ar31To60: number;
    ar61To90: number;
    ar91To120: number;
    ar121Plus: number;
    arCurrent: number;
    arOverdue: number;
    arTotal: number;
}

export interface Matter {
    activities: MatterActivity[];
    alertMessage: string | null;
    billingInstructions: string | null;
    billingFrequency: string | null;
    billerId: string | null;
    billerName: string | null;
    billToEmails: string[] | null;
    billToEmailsEditable: boolean;
    billingTimekeeperId: string | null;
    billingTimekeeperName: string | null;
    collectionTimekeeperId: string | null;
    collectionTimekeeperName: string | null;
    client: ClientIdentifier;
    createdById: string;
    canEdit: boolean | null;
    canSendStatement: boolean | null;
    createdOn: Date;
    collectorId: string | null;
    collectorName: string | null;
    currency: Currency;
    openedOn: Date | null;
    closedOn: Date | null;
    defaultInvoiceType: INVOICE_TYPE | null;
    computedStatementRecipients: string[] | null;
    description: string | null;
    doNotContact: boolean;
    doNotSendStatement: boolean;
    foreignCurrency: MatterForeignCurrency;
    id: string;
    leadMatter: boolean;
    matterId: string;
    matterType: MatterType;
    metrics: MatterMetrics | null;
    modifiedById: string;
    modifiedOn: string | null;
    name: string;
    office: KeyName | null;
    practiceGroup: KeyName | null;
    status: KeyName | null;
    feeArrangement: KeyName | null;
    payors: MatterPayor[];
    reminderDue: boolean;
    thirdParty: boolean | null;
    paymentTermsInDays: number | null;
    statementEmails: string[] | null;
    statements: MatterStatement[];
    unappliedCash: number | null;
    trustAmount: number | null;
    wipAmount: number | null;
    totalWorkAmount: number | null;
    totalBilledAmount: number;
    totalCollectedAmount: number;
    lastInvoicedOn: Date | null;
    lastPaidOn: Date | null;
    lastStatementSentOn: Date | null;
    tagNames: string[];
    writeOffAmount: number | null;
}

export type MatterStatement = Omit<Statement, 'activities' | 'client' | 'matter'>;

export type MatterIdentifier = {
    matterId: string;
    name: string;
};

export type MATTER_EVENT_TYPE = ACTIVITY_EVENT_TYPE.EVENT | ACTIVITY_EVENT_TYPE.MODIFIED;

export type MatterActivity = Activity & {
    eventType: MATTER_EVENT_TYPE;
};

export type MatterProperty = 'Payors' | 'Statements';

export enum MatterType {
    MATTER = 'Matter',
    GROUP = 'Group'
}

export enum DocumentDetailsActionsForMatters {
    ACTIVITY = 'activity',
    FINANCIALS = 'financials'
}

export interface MatterLookup {
    clientId: string;
    matterId: string;
    name: string;
}

// Backend does allow patching of many other properties. But for now
// these are enough. The properties can be updated as required
export interface MatterPatchProperties {
    doNotContact?: boolean | null;
    doNotSendStatement?: boolean | null;
    alertMessage?: string | null;
}

export interface MatterForeignCurrency {
    totalBilledAmount: number;
    totalCollectedAmount: number;
    totalWorkAmount: number;
    trustAmount: number | null;
    unappliedCash: number | null;
    wipAmount: number | null;
    writeOffAmount: number | null;
}

export type AssignedMatterTag = {
    matterTag: KeyName;
};
