import { useState } from 'react';
import './styles.css';
import FilterTag from './FilterTag';
import SearchSelect, {
    SearchSelectItem,
    Props as SearchSelectProps
} from '../components/searchSelect/SearchSelect';
import { isEqual } from 'lodash';

type Props = SearchSelectProps & {
    title: string;
};

export default function LookupFilter(props: Props) {
    const { title, defaultSelections = [], onSelect } = props;
    const [prevDefaultSelections, setPrevDefaultSelections] = useState(defaultSelections);
    const [selectionsCount, setSelectionsCount] = useState(defaultSelections.length);

    if (!isEqual(defaultSelections, prevDefaultSelections)) {
        setPrevDefaultSelections(defaultSelections);
        setSelectionsCount(defaultSelections.length);
    }

    const handleSelection = (selections: string[], selectionItems: SearchSelectItem[]) => {
        onSelect(selections, selectionItems);
        setSelectionsCount(selections.length);
    };

    return (
        <SearchSelect {...props} onSelect={handleSelection}>
            <div>
                <FilterTag title={title} checked={!!selectionsCount} badgeCount={selectionsCount} />
            </div>
        </SearchSelect>
    );
}
