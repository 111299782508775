import { Typography } from 'antd';
import { capitalize } from 'lodash';
import { AppConfig } from '../../../models/config/appConfig';
import { getFormattedDate, getRelativeDay, getRelativeTime } from '../../utils/datetime';
import TooltipComponent from '../TooltipComponent';
import './UserActivityInfo.css';

export type Props = {
    userName?: string;
    activityDateTime: Date;
    // If true, when calculating relative time and tooltip, ignore the time portion and also don't
    // show 'x hours/mins/secodns ago' or 'in x hours/mins/seconds'. The minimum portion is days.
    dateOnly?: boolean;
    edited?: boolean;
    deleted?: boolean;
    tooltipInfo?: string;
    prefix?: React.ReactNode;
    suffix?: React.ReactNode;
};

export default function UserActivityInfo({
    userName,
    activityDateTime,
    dateOnly,
    edited,
    deleted,
    tooltipInfo,
    prefix,
    suffix
}: Props) {
    const formattedDateTime = capitalize(
        getFormattedDate(
            activityDateTime,
            dateOnly ? AppConfig.dateMonthNameFormat : AppConfig.dateTimeMonthNameFormat
        )
    );

    return (
        <TooltipComponent
            title={
                tooltipInfo ??
                (dateOnly ? getRelativeDay(activityDateTime) : getRelativeTime(activityDateTime))
            }
        >
            {prefix && <Typography.Text className='user-activity-info'>{prefix}</Typography.Text>}
            <Typography.Text className='user-activity-info'>
                {userName ? `${userName} • ` : ''}
                {formattedDateTime}
                {suffix && <Typography.Text>{suffix}</Typography.Text>}
            </Typography.Text>
            {edited && !deleted && (
                <Typography.Text className='user-activity-info'>{` (edited)`}</Typography.Text>
            )}
            {deleted && (
                <Typography.Text className='user-activity-info' type='danger'>
                    {` (deleted)`}
                </Typography.Text>
            )}
        </TooltipComponent>
    );
}
