import dayjs from 'dayjs';
import { ACTIVITY_EVENT_TYPE, Activity } from '../../../app/models/activity';
import { InvoiceActivity } from '../../models/invoice';
import { StatementActivity } from '../../models/statement';
import { InvoiceStatusColor, StatementStatusColor, ThemeColor } from '../constants/color';
export const groupActivitiesByDate = (activity: Activity) => {
    if (dayjs(activity.createdOn).isAfter(dayjs().subtract(31, 'day').startOf('day'))) {
        return 'Recent';
    } else if (dayjs(activity.createdOn).isAfter(dayjs().subtract(121, 'day').startOf('day'))) {
        return 'Last Quarter';
    } else {
        return 'Older';
    }
};

export const getInvoiceActivities = (
    thirdParty = false,
    activities: InvoiceActivity[],
    onlyStatusChanged = false,
    eBillingServices?: boolean,
    payorId?: string,
    onlyWriteOff?: boolean
) => {
    // TODO:These are business logics which should ideally be handled in the backend,
    // But for now this is fine.
    // May be this is the best way rather than triggering api calls everytime
    if (payorId) {
        activities = activities.filter((activity) => activity.payor?.payorId === payorId);
    } else if (thirdParty) {
        activities = activities.filter((activity) => !activity.payor);
    }

    if (onlyStatusChanged) {
        activities = activities.filter(
            (activity) =>
                activity.eventType === ACTIVITY_EVENT_TYPE.STATUS_CHANGED ||
                activity.eventType === ACTIVITY_EVENT_TYPE.PROJECTED
        );
    }

    if (onlyWriteOff) {
        activities = activities.filter(
            (activity) => activity.eventType === ACTIVITY_EVENT_TYPE.WRITE_OFF
        );
    }

    if (eBillingServices) {
        activities = activities.filter((activity) => activity.services);
    }

    return activities;
};
export const getActivityColor = (
    activity: InvoiceActivity | StatementActivity,
    colorMap: typeof InvoiceStatusColor | typeof StatementStatusColor
): string | undefined => {
    if (activity.exception) {
        return ThemeColor.ColorError;
    }
    switch (activity.eventType) {
        case ACTIVITY_EVENT_TYPE.STATUS_CHANGED:
        case ACTIVITY_EVENT_TYPE.EVENT: {
            return colorMap[activity.status as keyof typeof colorMap];
        }
        case ACTIVITY_EVENT_TYPE.PROJECTED: {
            return '#D9D9D9';
        }
        case ACTIVITY_EVENT_TYPE.MODIFIED:
        default: {
            return '#9D9393';
        }
    }
};
