import { Col, DatePicker, Form, InputNumber, Row } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import FormLabel from '../../../../app/common/components/form/FormLabel';
import UploadDoc from '../../../../app/common/uploadFile/Upload';
import { isForeignCurrency } from '../../../../app/common/utils/tenant';
import { AppConfig } from '../../../../app/models/config/appConfig';
import { Currency, supportedUploadFileTypes } from '../../../../app/models/invoice';

export interface OnChangeData {
    month: Dayjs | null;
    amount: number | null;
    document: File | null;
    foreignCurrencyAmount: number | null;
    currency?: Currency | null;
}

type Props = {
    clientId: string;
    matterId?: string;
    onChange: (data: OnChangeData) => Promise<void>;
    onMonthChange: (dateString: Dayjs | null) => Promise<OnChangeData>;
    currency?: Currency;
    disabled?: boolean;
    disableDate?: boolean;
    defaultMonth?: Date;
};

export default observer(function StatementFetchModalBody(props: Props) {
    const {
        clientId,
        onChange,
        matterId,
        onMonthChange,
        currency,
        disabled,
        disableDate,
        defaultMonth
    } = props;

    const [month, setMonth] = useState<Dayjs | null>(defaultMonth ? dayjs(defaultMonth) : dayjs());
    const [amount, setAmount] = useState<number | null>(null);
    const [foreignCurrencyAmount, setForeignCurrencyAmount] = useState<number | null>(null);
    const [statementDocument, setStatementDocument] = useState<File | null>(null);
    const foreignCurrency = isForeignCurrency(currency);

    useEffect(() => {
        const fetchInitialData = async () => {
            const data = await onMonthChange(month);
            setAmount(data.amount ?? 0);
            setForeignCurrencyAmount(data.foreignCurrencyAmount ?? 0);
            setStatementDocument(data.document);

            await onChange({
                month,
                amount: data.amount,
                document: data.document,
                foreignCurrencyAmount: data.foreignCurrencyAmount,
                currency: data.currency
            });
        };
        fetchInitialData();
    }, [clientId, matterId]);

    const handleMonthChange = async (selectedMonth: Dayjs | null) => {
        setMonth(selectedMonth);
        const data = await onMonthChange(selectedMonth);
        if (data) {
            setAmount(data.amount ?? 0);
            setForeignCurrencyAmount(data.foreignCurrencyAmount ?? 0);
            setStatementDocument(data.document);
            await onChange(data);
        } else {
            setAmount(null);
            setForeignCurrencyAmount(null);
            setStatementDocument(null);
            await onChange({
                month: selectedMonth,
                amount: null,
                foreignCurrencyAmount: null,
                document: null,
                currency: null
            });
        }
    };

    const handleAmountChange = async (value: number | null) => {
        setAmount(value);
        await onChange({
            month,
            amount: value,
            document: statementDocument,
            foreignCurrencyAmount,
            currency
        });
    };

    const handleForeignCurrencyAmountChange = async (value: number | null) => {
        setForeignCurrencyAmount(value);
        await onChange({
            month,
            amount,
            document: statementDocument,
            foreignCurrencyAmount: value,
            currency
        });
    };

    const handleDocumentUpdate = async (files: UploadFile<any>[]) => {
        const updatedDocument = files.length ? (files[0] as RcFile) : null;
        setStatementDocument(updatedDocument);
        await onChange({
            month,
            amount,
            document: updatedDocument,
            foreignCurrencyAmount,
            currency: currency ?? null
        });
    };

    return (
        <Form layout='vertical' colon={false} disabled={disabled}>
            <Row gutter={[8, 0]}>
                <Col span={foreignCurrency ? 8 : 12}>
                    <Form.Item required label={<FormLabel label='Statement Month' />}>
                        <DatePicker
                            picker='month'
                            style={{ width: '100%' }}
                            value={month}
                            onChange={handleMonthChange}
                            disabled={disableDate}
                            disabledDate={(current) => current && current > dayjs().endOf('month')}
                        />
                    </Form.Item>
                </Col>
                <Col span={foreignCurrency ? 8 : 12}>
                    <Form.Item required label={<FormLabel label='Amount' />}>
                        <InputNumber
                            value={amount}
                            onChange={handleAmountChange}
                            keyboard={false}
                            precision={2}
                            controls={false}
                            style={{ width: '100%' }}
                            prefix={AppConfig.currencySymbol}
                        />
                    </Form.Item>
                </Col>
                {foreignCurrency && (
                    <Col span={8}>
                        <Form.Item
                            required
                            label={<FormLabel label={`Amount (${currency?.code})`} />}
                        >
                            <InputNumber
                                value={foreignCurrencyAmount}
                                onChange={handleForeignCurrencyAmountChange}
                                keyboard={false}
                                precision={2}
                                controls={false}
                                style={{ width: '100%' }}
                                prefix={currency?.symbol}
                            />
                        </Form.Item>
                    </Col>
                )}
            </Row>
            <Form.Item required label={<FormLabel label='Statement Document' />}>
                <UploadDoc
                    allowedExtensions={supportedUploadFileTypes.statement}
                    multiSelect={false}
                    onSelect={handleDocumentUpdate}
                    type='button'
                    fileList={statementDocument ? [statementDocument] : []}
                    showDownload
                    showPreview
                />
            </Form.Item>
        </Form>
    );
});
