import { Dropdown, MenuProps } from 'antd';
import { debounce, isEqual, uniqBy } from 'lodash';
import { PropsWithChildren, useState } from 'react';
import { trim } from '../../utils/string';
import DropdownRenderer from './DropdownRenderer';
import SearchSelectItemRenderer from './SearchSelectItemRenderer';
import './styles.css';

export type SearchSelectItem = {
    key: string;
    label: string;
};

export type Props = {
    onSearch: (queryText: string) => Promise<SearchSelectItem[]> | SearchSelectItem[];
    onSelect: (selections: string[], selectionItems: SearchSelectItem[]) => void;
    defaultItems?: SearchSelectItem[];
    defaultSelections?: string[];
    disabledSelections?: string[];
    disableEdit?: boolean;
};

export default function SearchSelect(props: PropsWithChildren<Props>) {
    const {
        onSearch,
        defaultItems = [],
        defaultSelections = [],
        children,
        onSelect,
        disabledSelections,
        disableEdit
    } = props;
    const [prevDefaultItems, setPrevDefaultItems] = useState<SearchSelectItem[]>(
        defaultItems ?? []
    );
    const [prevDefaultSelections, setPrevDefaultSelections] = useState<string[]>(
        defaultSelections ?? []
    );
    const [items, setItems] = useState<SearchSelectItem[]>(defaultItems ?? []);
    const [selections, setSelections] = useState<string[]>(defaultSelections ?? []);
    const [loading, setLoading] = useState(false);

    // Sometimes the defaultSelections might depend on an async call,
    // so we need to update it as necessary
    if (!isEqual(defaultItems, prevDefaultItems)) {
        setItems((prev) => uniqBy([...defaultItems, ...prev], 'key'));
        setPrevDefaultItems(defaultItems);
    }
    if (!isEqual(defaultSelections, prevDefaultSelections)) {
        setSelections(defaultSelections);
        setPrevDefaultSelections(defaultSelections);
    }

    const handleSearch = () => {
        const loadOptions = async (queryText: string) => {
            queryText = trim(queryText);
            if (queryText.length < 3) {
                return;
            }
            setLoading(true);
            const results = await onSearch(queryText);
            setItems((prev) => {
                const selectedItems = prev.filter((item) => selections.includes(item.key));
                return uniqBy([...selectedItems, ...results], 'key');
            });
            setLoading(false);
        };
        return debounce(loadOptions, 500);
    };

    const handleItemSelect = (selection: string, selected: boolean) => {
        const newSelections = selected
            ? [selection, ...selections]
            : selections.filter((selection_) => selection_ !== selection);
        setSelections(newSelections);
        onSelect(
            newSelections,
            items.filter((item) => newSelections.includes(item.key))
        );
    };

    const handleClearAll = () => {
        if (selections.length) {
            setSelections([]);
            onSelect([], []);
            setItems([]);
        }
    };

    const decoratedUnSelectedItems: MenuProps['items'] = uniqBy(items, 'key')
        .filter((item) => !selections.includes(item.key))
        .map(({ key, label }) => ({
            key,
            label: (
                <SearchSelectItemRenderer
                    label={label}
                    disabled={!!disabledSelections?.includes(key)}
                    onClick={() => handleItemSelect(key, true)}
                    checked={false}
                />
            ),
            style: { background: '#FFFFFF' }
        }));

    const menuProps: MenuProps = {
        items: decoratedUnSelectedItems,
        selectable: true,
        multiple: true,
        onClick: ({ key }) => handleItemSelect(key, true)
    };

    return (
        <Dropdown
            trigger={['click']}
            menu={menuProps}
            dropdownRender={(menus) => (
                <DropdownRenderer
                    menus={menus}
                    loading={loading}
                    onItemSelect={handleItemSelect}
                    onSearch={handleSearch()}
                    items={items}
                    selections={selections}
                    disableEdit={disableEdit}
                    onClearAll={handleClearAll}
                />
            )}
        >
            {children}
        </Dropdown>
    );
}
