import { findIndex } from 'lodash';
import { AppConfig } from '../../models/config/appConfig';
import {
    ARByType,
    ARTotal,
    AgedAR,
    CurrentMonthBillerSubmissions,
    InvoiceMetricsAndStatus,
    PendingInvoicesByBiller
} from '../../models/dashboard';
import { InvoiceSubGroupStatusColor } from '../constants/color';
import { getInvoiceStatusColorForPie, getTypeColor } from './invoice';
import { INVOICE_SUB_GROUP_STATUS } from '../../models/invoice';

export const dataTransformers = {
    pendingInvoices: (data: PendingInvoicesByBiller[]) => {
        const chartData: any[] = [];
        data.forEach((outsandingInvoiceByBiller) => {
            const valuesByType = outsandingInvoiceByBiller.valuesByType;
            const transformedData: any = { ...outsandingInvoiceByBiller };
            // Since we are not stacking (programmatically) and put one bar
            // behind the other, we have to get the cumulative / running sum

            transformedData['ePdfCount'] = valuesByType[0]?.count;
            transformedData['ePdfAmount'] = valuesByType[0]?.amount;
            transformedData['cumulativeEPdfCount'] = valuesByType[0]?.count;
            transformedData['cumulativeEPdfAmount'] = valuesByType[0]?.amount;

            transformedData['PhysicalCount'] = valuesByType[1]?.count;
            transformedData['PhysicalAmount'] = valuesByType[1]?.amount;
            transformedData['cumulativePhysicalCount'] =
                valuesByType[1]?.count + transformedData['cumulativeEPdfCount'];
            transformedData['cumulativePhysicalAmount'] =
                valuesByType[1]?.amount + transformedData['cumulativeEPdfAmount'];

            transformedData['eBillingCount'] = valuesByType[2]?.count;
            transformedData['eBillingAmount'] = valuesByType[2]?.amount;
            transformedData['cumulativeEBillingCount'] =
                valuesByType[2]?.count + transformedData['cumulativePhysicalCount'];
            transformedData['cumulativeEBillingAmount'] =
                valuesByType[2]?.amount + transformedData['cumulativePhysicalAmount'];

            delete transformedData['valuesByType'];
            chartData.push(transformedData);
        });
        return chartData;
    },

    arByType: (data: ARTotal) => {
        const chartData: { invoiceType: string; amount: number; color: string; count: number }[] =
            [];
        const numberOfInvoiceTypes = 3;
        for (let index = 0; index < numberOfInvoiceTypes; index++) {
            chartData.push({
                invoiceType: data.byType[index].invoiceTypeName,
                amount: data.byType[index].amount,
                color: getTypeColor(data.byType[index].invoiceType),
                count: data.byType[index].count
            });
        }
        return chartData;
    },

    arByStatus: (data: ARByType) => {
        const chartData: { invoiceStatus: string; amount: number; color: string; count: number }[] =
            [];
        data.byStatus.forEach((byStatusInfo) => {
            if (byStatusInfo.amount === 0) {
                return;
            }
            chartData.push({
                invoiceStatus: byStatusInfo.invoiceStatusName,
                amount: byStatusInfo.amount,
                color: getInvoiceStatusColorForPie(byStatusInfo.invoiceStatus),
                count: byStatusInfo.count
            });
        });
        return chartData;
    },

    agedAR: (data: AgedAR[]) => {
        const chartData: any = [];
        data.forEach((agedAR) => {
            const byType = agedAR.byType;
            const transformedData: any = { ...agedAR };

            // Since we are not stacking (programmatically) and put one bar
            // behind the other, we have to get the cumulative / running sum

            transformedData['ePdfCount'] = byType[0]?.count;
            transformedData['ePdfAmount'] = byType[0]?.amount;
            transformedData['cumulativeEPdfCount'] = byType[0]?.count;
            transformedData['cumulativeEPdfAmount'] = byType[0]?.amount;

            transformedData['PhysicalCount'] = byType[1]?.count;
            transformedData['PhysicalAmount'] = byType[1]?.amount;
            transformedData['cumulativePhysicalCount'] =
                byType[1]?.count + transformedData['cumulativeEPdfCount'];
            transformedData['cumulativePhysicalAmount'] =
                byType[1]?.amount + transformedData['cumulativeEPdfAmount'];

            transformedData['eBillingCount'] = byType[2]?.count;
            transformedData['eBillingAmount'] = byType[2]?.amount;
            transformedData['cumulativeEBillingCount'] =
                byType[2]?.count + transformedData['cumulativePhysicalCount'];
            transformedData['cumulativeEBillingAmount'] =
                byType[2]?.amount + transformedData['cumulativePhysicalAmount'];
            transformedData['totalAmount'] =
                transformedData['PhysicalAmount'] +
                transformedData['eBillingAmount'] +
                transformedData['ePdfAmount'];
            transformedData['totalCount'] =
                transformedData['eBillingCount'] +
                transformedData['PhysicalCount'] +
                transformedData['ePdfCount'];
            delete transformedData['byType'];
            chartData.push(transformedData);
        });
        return chartData;
    },

    currentMonthBillerSubmissions: (data: CurrentMonthBillerSubmissions[]) => {
        const chartData: any = [];
        data.forEach((currentMonthBillerSubmissions) => {
            const byType = currentMonthBillerSubmissions.byType;
            const transformedData: any = { ...currentMonthBillerSubmissions };

            // Since we are not stacking (programmatically) and put one bar
            // behind the other, we have to get the cumulative / running sum

            transformedData['ePdfSubmissionCount'] = byType[0]?.submissionCount;
            transformedData['ePdfSubmissionAmount'] = byType[0]?.submissionAmount;
            transformedData['ePdfTotalCount'] = byType[0]?.totalCount;
            // transformedData['ePdfTotalAmount'] = byType[0]?.totalAmount;
            transformedData['cumulativeEPdfSubmissionCount'] = byType[0]?.submissionCount;
            transformedData['cumulativeEPdfSubmissionAmount'] = byType[0]?.submissionAmount;

            transformedData['PhysicalSubmissionCount'] = byType[1]?.submissionCount;
            transformedData['PhysicalSubmissionAmount'] = byType[1]?.submissionAmount;
            transformedData['PhysicalTotalCount'] = byType[1]?.totalCount;
            // transformedData['PhysicalTotalAmount'] = byType[1]?.totalAmount;
            transformedData['cumulativePhysicalSubmissionCount'] =
                byType[1]?.submissionCount + transformedData['cumulativeEPdfSubmissionCount'];
            transformedData['cumulativePhysicalSubmissionAmount'] =
                byType[1]?.submissionAmount + transformedData['cumulativeEPdfSubmissionAmount'];

            transformedData['eBillingSubmissionCount'] = byType[2]?.submissionCount;
            transformedData['eBillingSubmissionAmount'] = byType[2]?.submissionAmount;
            transformedData['eBillingTotalCount'] = byType[2]?.totalCount;
            // transformedData['eBillingTotalAmount'] = byType[2]?.totalAmount;
            transformedData['cumulativeEBillingSubmissionCount'] =
                byType[2]?.submissionCount + transformedData['cumulativePhysicalSubmissionCount'];
            transformedData['cumulativeWBillingSubmissionAmount'] =
                byType[2]?.submissionAmount + transformedData['cumulativePhysicalSubmissionAmount'];

            delete transformedData['byType'];
            chartData.push(transformedData);
        });
        return chartData;
    },
    myInvoiceMetrics: (data: InvoiceMetricsAndStatus[]) => {
        const chartData: any[] = [];
        data.forEach((invoiceMetricsAndStatus) => {
            chartData.push({
                ...invoiceMetricsAndStatus.metrics,
                status: invoiceMetricsAndStatus.status,
                statusName: invoiceMetricsAndStatus.statusName,
                fill: InvoiceSubGroupStatusColor[invoiceMetricsAndStatus.status]
            });
        });

        // Since backend always sends the statuses in same order,
        // add ghost data to show some gap between the groupstatuses
        // Add a gap after NotSubmitted.
        const notSubmittedIndex = findIndex(
            chartData,
            (data: any) => data.status === INVOICE_SUB_GROUP_STATUS.NOT_SUBMITTED
        );
        chartData.splice(notSubmittedIndex + 1, 0, { gap: true });

        // Add a gap before Paid
        chartData.splice(chartData.length - 1, 0, { gap: true });

        // Add a ghost bar which will be replaces by svg text
        chartData.splice(chartData.length - 1, 0, { dummy: true });

        return chartData;
    }
};

export const dataFormatters = {
    amount: (
        compact = false,
        prefixSymbol = true,
        maxDecimalDigits = 0,
        emptyIndicator?: string
    ) => {
        let options: any = {};
        if (compact) {
            options = { ...options, notation: 'compact', compactDisplay: 'short' };
        }
        // In this example, the `currency` and `locale` are not provided, so you should replace AppConfig.currency and AppConfig.locale with the actual values.
        if (prefixSymbol) {
            options = {
                ...options,
                style: 'currency',
                currency: AppConfig.currency
            };
        }
        options = { ...options, maximumFractionDigits: maxDecimalDigits };

        return (amount: number) => {
            if (emptyIndicator !== undefined && amount === 0) {
                return emptyIndicator;
            }
            return new Intl.NumberFormat(AppConfig.locale, options).format(amount);
        };
    },
    count: (compact = false, maxDecimalDigits = 0, emptyIndicator?: string) => {
        let options: any = {};
        if (compact) {
            options = { ...options, notation: 'compact', compactDisplay: 'short' };
        }

        options = { ...options, maximumFractionDigits: maxDecimalDigits };

        return (count: number) => {
            if (emptyIndicator && count === 0) {
                return emptyIndicator;
            }
            return new Intl.NumberFormat(AppConfig.locale, options).format(count);
        };
    }
};

export enum INVOICE_TYPE_ORDER {
    ePdf,
    Physical,
    eBilling
}
