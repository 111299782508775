import { Button, Checkbox, Flex, Space, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import Loader from '../../../../app/common/components/Loader';
import { Notify } from '../../../../app/common/utils/notify';
import { isForeignCurrency } from '../../../../app/common/utils/tenant';
import { Currency } from '../../../../app/models/invoice';
import { ClientOrMatterStatement } from '../../../../app/models/statement';
import { useStore } from '../../../../app/stores/store';
import StatementFetchModalBody, {
    OnChangeData
} from '../../../statements/hooks/components/StatementFetchModalBody';
import useStatementActions from '../../../statements/hooks/useStatementActions';

type Props = {
    clientId: string;
    matterId: string;
    currency?: Currency;
    onSuccess?: () => Promise<any> | any;
};

export default observer(function UploadMatterStatementModalBody(props: Props) {
    const { clientId, matterId, onSuccess, currency } = props;
    const foreignCurrency = isForeignCurrency(currency);
    const [statementMonth, setStatementMonth] = useState<Dayjs | null>(null);
    const [statementDocument, setStatementDocument] = useState<File | null>(null);
    const [amount, setAmount] = useState<number | null>(0);
    const [foreignCurrencyAmount, setForeignCurrencyAmount] = useState<number | null>(0);
    const [existingStatement, setExistingStatement] = useState<ClientOrMatterStatement | null>(
        null
    );
    const [overrideUpload, setOverrideUpload] = useState(false);

    const {
        statementStore: { createStatement, creatingStatement, loadingStatementForMonth },
        modalStore: { closeModal }
    } = useStore();

    const { handleLoadStatementDocument, handleFetchStatement } = useStatementActions();

    const handleMonthChange = useCallback(
        async (dateString: Dayjs | null) => {
            let result: OnChangeData = {
                month: null,
                amount: null,
                document: null,
                foreignCurrencyAmount: null,
                currency: null
            };
            if (!clientId || !dateString) {
                return result;
            }

            const statement = await handleFetchStatement(clientId, dateString, matterId);
            setExistingStatement(statement);
            if (statement) {
                const document = await handleLoadStatementDocument(statement);

                if (document) {
                    setStatementDocument(document);

                    result = {
                        month: dateString,
                        amount: statement.amount,
                        document: document,
                        foreignCurrencyAmount: statement.foreignCurrency?.amount ?? null,
                        currency: statement.currency
                    };
                }
            }
            return result;
        },
        [clientId, matterId]
    );

    const handleOnChange = async (data: OnChangeData) => {
        setStatementDocument(data.document);
        setStatementMonth(data?.month);
        setAmount(data.amount);
        setForeignCurrencyAmount(data.foreignCurrencyAmount);
    };

    const handleOnOK = async () => {
        if (
            statementDocument &&
            clientId &&
            amount !== null &&
            statementMonth &&
            (foreignCurrency ? foreignCurrencyAmount !== null : true)
        ) {
            const success = await createStatement(clientId, {
                statementId: null,
                statementDocument: statementDocument,
                statementMonth: dayjs(statementMonth).format('YYYY-MM'),
                amount: amount !== null ? amount : (existingStatement?.amount ?? null),
                matterId: matterId,
                foreignCurrencyAmount: foreignCurrency ? foreignCurrencyAmount : null
            });

            if (success) {
                Notify.success('Statement uploaded successfully.');
                closeModal();
            }
            onSuccess?.();
        }
    };

    const disableSendButton = !(
        statementDocument &&
        amount !== null &&
        statementMonth &&
        (!foreignCurrency || foreignCurrencyAmount !== null) &&
        (!existingStatement?.canShare || overrideUpload)
    );

    return (
        <Loader spinning={creatingStatement || loadingStatementForMonth}>
            <Space direction='vertical' style={{ width: '100%' }}>
                <StatementFetchModalBody
                    clientId={clientId}
                    currency={currency}
                    matterId={matterId}
                    onChange={handleOnChange}
                    onMonthChange={handleMonthChange}
                />
                {existingStatement?.canShare && (
                    <Checkbox onChange={(e) => setOverrideUpload(e.target.checked)}>
                        This Statement was previously sent to the Client. Please confirm that you
                        want to re-upload it.
                    </Checkbox>
                )}
                <Typography.Text type='secondary'>
                    Saving this will overwrite any existing Statement for this month for the
                    selected Matter and mark it as not sent.
                </Typography.Text>
                <Flex justify='flex-end'>
                    <Space>
                        <Button onClick={() => closeModal()}>Cancel</Button>
                        <Button onClick={handleOnOK} type='primary' disabled={disableSendButton}>
                            Save
                        </Button>
                    </Space>
                </Flex>
            </Space>
        </Loader>
    );
});
