import { Button, Checkbox, Col, Flex, Form, Row, SelectProps, Space, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import FormLabel from '../../../../app/common/components/form/FormLabel';
import Loader from '../../../../app/common/components/Loader';
import { Notify } from '../../../../app/common/utils/notify';
import { isForeignCurrency } from '../../../../app/common/utils/tenant';
import { Currency } from '../../../../app/models/invoice';
import { ClientOrMatterStatement } from '../../../../app/models/statement';
import { useStore } from '../../../../app/stores/store';
import MatterLookup from '../../../shared/lookup/MatterLookup';
import useStatementActions from '../useStatementActions';
import StatementFetchModalBody, { OnChangeData } from './StatementFetchModalBody';

type Props = {
    clientId: string;
    currency: Currency;
    onSuccess?: () => Promise<any> | any;
};

export default observer(function UploadClientStatementModalBody(props: Props) {
    const { clientId, currency, onSuccess } = props;
    const [matterOptions, setMatterOptions] = useState<SelectProps<object>['options']>([]);
    const foreignCurrency = isForeignCurrency(currency);
    const [statementMonth, setStatementMonth] = useState<Dayjs | null>(null);
    const [statementDocument, setStatementDocument] = useState<File | null>(null);
    const [amount, setAmount] = useState<number | null>(0);
    const [foreignCurrencyAmount, setForeignCurrencyAmount] = useState<number | null>(0);
    const [matterId, setMatterId] = useState<string | undefined>(undefined);
    const [existingStatement, setExistingStatement] = useState<ClientOrMatterStatement | null>(
        null
    );
    const [overrideUpload, setOverrideUpload] = useState(false);

    const {
        matterStore: { lookupMatters },
        statementStore: { createStatement, creatingStatement, loadingStatementForMonth },
        modalStore: { closeModal }
    } = useStore();

    const { handleLoadStatementDocument, handleFetchStatement } = useStatementActions();

    useEffect(() => {
        const fetchoptions = async () => {
            const matters = await lookupMatters(clientId, '');
            if (matters) {
                setMatterOptions(
                    matters.map(({ matterId, name }) => ({
                        label: `${matterId} - ${name}`,
                        value: matterId
                    })) ?? []
                );
            } else {
                setMatterOptions([]);
            }
        };
        fetchoptions();
    }, []);

    const handleMonthChange = async (dateString: Dayjs | null) => {
        let result: OnChangeData = {
            month: null,
            amount: null,
            document: null,
            foreignCurrencyAmount: null,
            currency: null
        };

        if (!clientId || !dateString) {
            return result;
        }

        const statement = await handleFetchStatement(clientId, dateString, matterId);

        setExistingStatement(statement);

        if (statement) {
            const document = await handleLoadStatementDocument(statement);
            if (document) {
                setStatementDocument(document);
                result = {
                    month: dateString,
                    amount: statement.amount,
                    document: document,
                    foreignCurrencyAmount: statement.foreignCurrency?.amount ?? null,
                    currency: statement.currency
                };
            }
        }
        return result;
    };

    const handleOnChange = async (data: OnChangeData) => {
        setStatementDocument(data.document);
        setStatementMonth(data?.month);
        setAmount(data.amount);
        setForeignCurrencyAmount(data.foreignCurrencyAmount);
    };

    const handleClearMatter = () => {
        setMatterId(undefined);
        setStatementDocument(null);
        setAmount(null);
        setForeignCurrencyAmount(null);
        setExistingStatement(null);
    };

    const handleOnOK = async () => {
        if (
            statementDocument &&
            clientId &&
            amount !== null &&
            statementMonth &&
            (foreignCurrency ? foreignCurrencyAmount !== null : true)
        ) {
            const success = await createStatement(clientId, {
                statementId: null,
                statementDocument: statementDocument,
                statementMonth: dayjs(statementMonth).format('YYYY-MM'),
                amount: amount !== null ? amount : (existingStatement?.amount ?? null),
                matterId: matterId ?? null,
                foreignCurrencyAmount: foreignCurrency ? foreignCurrencyAmount : null
            });
            if (success) {
                Notify.success('Statement uploaded successfully.');
                onSuccess?.();
            }
            closeModal();
        }
    };

    const disableSendButton = !(
        statementDocument &&
        amount !== null &&
        statementMonth &&
        (!foreignCurrency || foreignCurrencyAmount !== null) &&
        (!existingStatement?.canShare || overrideUpload)
    );

    return (
        <Loader spinning={creatingStatement || loadingStatementForMonth}>
            <Space direction='vertical' style={{ width: '100%' }}>
                <Row gutter={[8, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label={<FormLabel label='Matter (optional)' />}
                            colon={false}
                            layout='vertical'
                        >
                            <MatterLookup
                                clientId={clientId}
                                onSelect={(value) => setMatterId(value)}
                                options={matterOptions}
                                onClear={handleClearMatter}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <StatementFetchModalBody
                    clientId={clientId}
                    currency={currency}
                    matterId={matterId}
                    onChange={handleOnChange}
                    onMonthChange={handleMonthChange}
                />
                {existingStatement?.canShare && (
                    <Form.Item>
                        <Checkbox onChange={(e) => setOverrideUpload(e.target.checked)}>
                            This {matterId ? 'matter' : ''} statement was previously sent to the
                            Client. Please confirm that you want to re-upload it.
                        </Checkbox>
                    </Form.Item>
                )}
                <Typography.Text type='secondary'>
                    Saving this will overwrite any existing Statement for this month for the
                    selected Client (and Matter, if specified) and mark it as not sent.
                </Typography.Text>
                <Flex justify='flex-end'>
                    <Space>
                        <Button onClick={() => closeModal()}>Cancel</Button>
                        <Button onClick={handleOnOK} type='primary' disabled={disableSendButton}>
                            {'Save'}
                        </Button>
                    </Space>
                </Flex>
            </Space>
        </Loader>
    );
});
