import {
    AssignedClientTag,
    Client,
    ClientActivity,
    ClientInvoiceMetrics,
    ClientLookup,
    ClientPatchProperties,
    ClientStatement
} from '../models/client';
import { Invoice } from '../models/invoice';
import { PaginatedResult } from '../models/list/pagination';
import { Matter } from '../models/matter';
import { Note } from '../models/note';
import { CreateOrUpdatePaymentPlan, PaymentPlan } from '../models/paymentPlan';
import { StatementActivity } from '../models/statement';
import { requests } from './axiosAgent';

const Clients = {
    BASE_URL: '/clients',
    getClientIdentifierUrl: (clientId: string) => `${Clients.BASE_URL}/${clientId}`,
    invitedList: (params: URLSearchParams) =>
        requests.get<PaginatedResult<Client>>(Clients.BASE_URL, {
            params
        }),
    lookup: (params: URLSearchParams) =>
        requests.get<ClientLookup[]>(`${Clients.BASE_URL}/lookup`, { params }),
    get: (clientId: string, params?: URLSearchParams) =>
        requests.get<Client>(Clients.getClientIdentifierUrl(clientId), { params }),
    patchClient: (clientId: string, patchProperties: ClientPatchProperties) =>
        requests.patch<void>(Clients.getClientIdentifierUrl(clientId), patchProperties),
    invite: (clientId: string, params: URLSearchParams) =>
        requests.post<void>(
            `${Clients.getClientIdentifierUrl(clientId)}/_requestportalaccess`,
            {},
            { params }
        ),
    disablePortalAccess: (clientId: string) =>
        requests.post<void>(`${Clients.getClientIdentifierUrl(clientId)}/_disableportalaccess`),
    enablePortalAccess: (clientId: string) =>
        requests.post<void>(`${Clients.getClientIdentifierUrl(clientId)}/_enableportalaccess`),
    createNote: (clientId: string, content: string, tagKeys: string[]) =>
        requests.post<Note>(`${Clients.getClientIdentifierUrl(clientId)}/notes`, {
            content,
            tagKeys
        }),
    getNotes: (clientId: string, params: URLSearchParams) =>
        requests.get<PaginatedResult<Note>>(`${Clients.getClientIdentifierUrl(clientId)}/notes`, {
            params
        }),
    getClientInvoiceMetrics: (clientId: string) =>
        requests.get<ClientInvoiceMetrics>(
            `${Clients.getClientIdentifierUrl(clientId)}/invoicemetrics`
        ),
    getAllClients: (params: URLSearchParams) =>
        requests.get<PaginatedResult<Client>>(Clients.BASE_URL, { params }),
    exportClientsToExcel: (params: URLSearchParams) =>
        requests.get<Blob>(`${Clients.BASE_URL}/_export`, { params, responseType: 'blob' }),
    getMatters: (clientId: string, params: URLSearchParams) =>
        requests.get<PaginatedResult<Matter>>(
            `${Clients.getClientIdentifierUrl(clientId)}/matters`,
            { params }
        ),
    exportMattersToExcel: (clientId: string, params: URLSearchParams) =>
        requests.get<Blob>(`${Clients.getClientIdentifierUrl(clientId)}/matters/_export`, {
            params,
            responseType: 'blob'
        }),
    shareClientWithTimekeeper: (
        clientId: string,
        body: {
            comment: string;
            shareWithBillingTimekeeper?: boolean;
            shareWithCollectionTimekeeper?: boolean;
        }
    ) =>
        requests.post<void>(`${Clients.getClientIdentifierUrl(clientId)}/_sharewithtimekeeper`, {
            ...body
        }),
    submitInvoices: (
        clientId: string,
        body: {
            emailMessage: string | null;
            emailSubject: string | null;
            ccCurrentUser?: boolean;
            ccBillingTimekeeper?: boolean;
        }
    ) =>
        requests.post<void>(`${Clients.getClientIdentifierUrl(clientId)}/invoices/_submit`, {
            ...body
        }),
    getInvoices: (clientId: string, params: URLSearchParams) =>
        requests.get<PaginatedResult<Invoice>>(
            `${Clients.getClientIdentifierUrl(clientId)}/invoices`,
            { params }
        ),
    exportInvoicesToExcel: (clientId: string, params: URLSearchParams) =>
        requests.get<Blob>(`${Clients.getClientIdentifierUrl(clientId)}/invoices/_export`, {
            params,
            responseType: 'blob'
        }),
    getStatementActivities: (clientId: string) =>
        requests.get<StatementActivity[]>(
            `${Clients.getClientIdentifierUrl(clientId)}/statements/activities`
        ),
    getClientActivities: (clientId: string) =>
        requests.get<ClientActivity[]>(`${Clients.getClientIdentifierUrl(clientId)}/activities`),
    updateAttachDocumentsToEmail: (clientId: string, attachdocumentstoemail: boolean) =>
        requests.put<void>(`${Clients.getClientIdentifierUrl(clientId)}/attachdocumentstoemail`, {
            attachdocumentstoemail
        }),
    getAssignedClientTags: (clientId: string) =>
        requests.get<AssignedClientTag[]>(`${Clients.getClientIdentifierUrl(clientId)}/tags`),
    updateAssignedClientTags: (clientId: string, tagKeys: string[]) =>
        requests.put<void>(`${Clients.getClientIdentifierUrl(clientId)}/tags`, tagKeys),
    updateBillToEmails: (clientId: string, billToEmails: string[], provisional: boolean) =>
        requests.put<void>(`${Clients.getClientIdentifierUrl(clientId)}/billToEmails`, {
            billToEmails,
            provisional
        }),
    updateStatementEmails: (clientId: string, statementEmails: string[], provisional: boolean) =>
        requests.put<void>(`${Clients.getClientIdentifierUrl(clientId)}/statementemails`, {
            statementEmails,
            provisional
        }),
    getClientStatements: (clientId: string, params: URLSearchParams) =>
        requests.get<PaginatedResult<ClientStatement>>(
            `${Clients.getClientIdentifierUrl(clientId)}/statements`,
            { params }
        ),
    updateDefaultInvoiceType: (
        clientId: string,
        defaultInvoiceType: string | null,
        cascadeUpdate: boolean
    ) =>
        requests.put<void>(`${Clients.getClientIdentifierUrl(clientId)}/defaultinvoicetype`, {
            defaultInvoiceType,
            cascadeUpdate
        }),
    createPaymentPlan: (clientId: string, body: CreateOrUpdatePaymentPlan) =>
        requests.post<PaymentPlan>(`${Clients.getClientIdentifierUrl(clientId)}/paymentplans`, {
            ...body
        }),
    syncConversations: () => requests.get(`${Clients.BASE_URL}/conversations/_sync`),
    getClientPaymentlink: (clientId: string) =>
        requests.get<string>(`${Clients.getClientIdentifierUrl(clientId)}/paymentLink`)
};

export default Clients;
